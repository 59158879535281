.map-container {
    position: relative;
    width: 100%;
    height: auto;
    img {
        max-width: 100%;
    }
    /*    &__bubble-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background: red;

    }*/
    &__text {
        position: absolute;
        display: block;
        width: 0px;
        height: 200px;
        border-style: solid;
        border-width: 0px;
        border-color: rgba(0, 186, 197, .3);
        border-radius: 20px;
        background-color: rgba(0, 186, 197, .4);
        transition: width 500ms;
        color: $dark-gray;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        overflow: hidden;
        margin-top: -80px;
        margin-left: -5px;
        z-index: 2;
        &__inside {
            position: relative;
            top: 25px;
            display: table;
            width: 356px;
            height: 150px;
            margin-right: auto;
            margin-left: auto;
            border: 1px solid $color-cyan-light;
            border-radius: 20px;
            background-color: $color-white;
            opacity: 0;
            transition: opacity 500ms;
            padding:0 25px;
            overflow: hidden;
            color: $primary-font-color;
            text-align: left;
            font-size: 15px;
            line-height: 20px;
            font-weight: 400;
            p {
                margin: 0;
                padding: 0;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
    &__bubble {
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        margin-right: auto;
        margin-left: auto;
        border: 4px solid $color-black;
        border-radius: 100px;
        background-color: $color-white;
        left: 169px;
        top: 185px;
        cursor: pointer;
        z-index: 1;
        &:before{
          position: absolute;
          display: block;
          width: 30px;
          height: 30px;
          margin-right: auto;
          margin-left: auto;
          border: 4px solid $color-black;
          border-radius: 100px;
          background-color: $color-white;
          left: -4px;
          top: -4px;
          cursor: pointer;
          z-index: 1;
          content: '';
        }
        &:after {
            position: absolute;
            content: '';
            width: 40px;
            height: 40px;
            -webkit-animation: circle_highlight 1.5s infinite;
            /* Safari 4.0 - 8.0 */
            animation: circle_highlight 1.5s infinite;

            border-radius: 50%;
            top: -9px;
            left: -9px;
            border: 1px solid $color-black;
            z-index: 0;
            background: hsla(86, 66%, 83%, .8);
        }


    }

    @media only screen and (max-width: 736px) {
        display:none;
    }
}
.bubble-open {
    width: 400px;
    border-width: 1px;
    .map-container__text__inside {
        opacity: 1;
        transition: opacity 500ms;
        transition-delay: 0.5s;
    }
}
map {
    display: none;
}
.postop {
    z-index: 3;
}

.mobile-map{
    display: none;
    @media only screen and (max-width: 736px) {
        display:block;
        .accordion-panel .panel .panel-heading a .header-content-field{
            padding-left: 0;
        }
        .accordion-panel .panel .panel-body{
            padding: 40px 20px 40px 20px;
        }

        .accordion-panel .panel .panel-heading a{
            padding-left: 10px;
        }

    }
}

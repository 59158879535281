.leftside-navigation {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    display: block;
    overflow: auto;
    border-top: 1px solid $border-grey;
    background-color: $color-white;
    background-image: url(../images/pattern.png);
    background-size: 5px;
    opacity: 0;
    width: 0;
    -webkit-transform: translate(-100%, 0px);
    -ms-transform: translate(-100%, 0px);
    transform: translate(-100%, 0px);
    border-bottom: 1px solid $dark-gray;
    &__logo {
        visibility: collapse;
        margin-top: 22px;
        margin-left: 20px;
        img {
            width: 90%;
            max-width: 172px;
            vertical-align: middle;
            display: inline-block;
            visibility: visible;
        }
    }
    &__close {
        a {
            margin-top: 40px;
            margin-right: 0px;
            margin-bottom: 20px;
            padding: 0px 9px;
            float: right;
            background-color: $color-cyan-light;
            -webkit-transition: background-color 350ms cubic-bezier(.645, .045, .355, 1), color 450ms cubic-bezier(.645, .045, .355, 1);
            transition: background-color 350ms cubic-bezier(.645, .045, .355, 1), color 450ms cubic-bezier(.645, .045, .355, 1);
            font-family: $icon-font;
            color: $color-white;
            font-size: 30px;
            text-decoration: none;
            cursor: pointer;
            &:hover {
                color: $color-medium-black;
            }
        }
    }
    .col-md-4 {
        >a {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 40px;
            margin-bottom: 20px;
            padding-right: 20px;
            padding-left: 20px;
            background-color: $color-medium-black;
            color: $color-white;
            font-size: 15px;
            line-height: 40px;
            text-decoration: none;
            cursor: pointer;
            display: block;
            font-weight: 400;
            &:hover {
                color: $color-cyan;
            }
        }
        ul {
            margin-bottom: 40px;
            padding-top: 0px;
            padding-left: 20px;
            list-style: none;
            margin-top: 15px;
            li {
                a {
                    width: 100%;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-left: 15px;
                    border-bottom: 1px solid rgba(44, 44, 44, .11);
                    color: $color-medium-black;
                    font-weight: 400;
                    letter-spacing: 0px;
                    text-decoration: none;
                    cursor: pointer;
                    display: block;
                    -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 250ms cubic-bezier(.645, .045, .355, 1);
                    transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 250ms cubic-bezier(.645, .045, .355, 1);
                    &:hover {
                        background: $color-cyan;
                        color: $color-white;
                    }
                }
                &:last-child{
                  a{
                    border-bottom: 0;
                  }
                }
            }
        }
    }
    .footer {
        @media only screen and (max-height: 1200px) {
            position: relative;
        }
    }
}
.left-menu-slide-in {
    opacity: 1;
    width: 100%;
    transform-style: preserve-3d;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    transition: transform 400ms;
    -ms-transition: all 400ms;
}
.left-menu-slide-out {
    transform-style: preserve-3d;
    display: block;
    transform: translateX(150%) translateY(0px) translateZ(0px);
    transition: transform 1000ms;
}

.image-text-module {
    border: 1px solid $border-color-light-gray;
    background: $color-white;
    height: 100%;
    @media only screen and (max-width: 1024px) {
        min-height: 400px;
        height:100%;
    }
    @media only screen and (max-width: 990px) {
        min-height: 400px;
        height: auto;
        margin-bottom: 40px;
    }
    @media only screen and (max-width: 768px) {
        
    }
    @media only screen and (max-width: 767px) {
        min-height: 400px;
        height:inherit;
    }
    &__headline {
        color: $color-cyan-light;
        cursor: pointer;
        font-size: 26px;
        font-weight: 300;
        padding: 25px 30px 4px 30px;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        margin: 0;
        line-height: initial;
        line-height: 26px;
        @media only screen and (max-width: 476px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    &__bodytext {
        color: $primary-font-color;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 14px;
        text-align: justify;
        font-weight: 300;
        line-height: 25px;
        font-size: 16px;
        padding-bottom: 30px;
        @media only screen and (max-width: 768px) {
            line-height: 18px;
            font-size: 14px;
        }
        @media only screen and (max-width: 476px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    img {
        height: 60%;
        left: 0;
        position: relative;
        right: 0;
        top: 0;
        width: auto;
        display: inline-block;
        max-width: 100%;
        vertical-align: middle;
        max-width: 100%;
        width: 100%;
        @media only screen and (max-width: 1100px) {
            height: 45%;
        }
    }
    @media only screen and (max-width: 476px) {
        margin-left: -15px;
        margin-right: -15px;
    }
}

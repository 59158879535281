.newsfilter {
    &__year {
        margin-top: 0px;
        margin-bottom: 20px;
        color: $color-cyan-light;
        font-size: 28px;
        line-height: 34px;
        font-weight: 300;
    }
    .filter-wrap {
        padding-top: 30px;
        padding-bottom: 30px;
        border: 1px solid $module-light-border;
        background-image: url(../images/pattern.png);
        background-position: 0px 0px;
        background-size: 5px;
        padding-right: 20px;
        padding-left: 20px;
        width: 50%;
        max-width: 492px;
        margin-bottom: 40px;
        &__label {}
        &__select-wrap{
            display: inline-block;
            width: 215px;
            margin-right: 15px;
            position: relative;
            @media only screen and (max-width: 960px) {
                width: 208px;
            }
        select {
            border: 0;
            border-bottom: 1px solid $border-grey;
            background-color: $color-white;
            color: $color-cyan-light;
            padding: 0;
            padding-left: 20px;
            width: 100%;
            height: 48px;
            box-shadow: 1px 1px 2px 0 rgba(44, 44, 44, .15);
            font-size: 16px;
            line-height: 25px;
            font-weight: 400;

            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
            @media only screen and (max-width: 1348px) {
                margin-bottom: 10px;
            }


            &:focus {
                outline: none;
                outline-style: none;
                border-bottom: 1px solid $color-cyan-light;
            }
        }
        .select-arrow{
            &:before{
                width: 15px;
                height: 15px;
                position: absolute;
                font-family: $icon-font;
                right: 15px;
                top:10px;
                content:"\76" ;
                font-style: normal;
                color:$color-cyan;
                pointer-events: none;
                }
            }
        }
        &__select-wrap-right{
            margin-right: 0px;
            @media only screen and (max-width: 460px) {
              margin-top: 10px;
            }
        }

        @media only screen and (max-width: 960px) {
            width:100%;
            max-width:100%;
        }
    }


    .box-list-full{

        ul{
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .latest-news{


            &__item{
                border-bottom: 1px solid #e7e7e7;
                margin-top: 35px;
                 margin-bottom: 35px;
                padding-bottom:35px;
                .eqs-item-date{
                    font-size: 12px;
                    font-weight: 300;
                }
                .news-art{
                        padding-left: 10px;
                        font-size: 12px;
                        font-weight: 500;
                        text-transform: uppercase;
                        cursor: pointer;
                }


            }
            &__title{
                    position: relative;
                    margin-bottom: 20px;
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 300;
                    text-align: left;
                    cursor: pointer;
                    margin-top: 5px;
                    &:hover{
                        text-decoration:underline;
                        color: $color-cyan;
                    }
            }
        }
        .news-noborder{
            border-bottom: 0;
        }
    }
    .pagination{
        display: none;
    }

}
.no-match-found{
  margin-bottom: 40px;
}

.page-not-found {
    padding-top: 100px;
    padding-bottom: 100px;
    .ce-image {
        text-align: center;
        margin: 60px 0;
    }
    p{
        font-size: 16px;
    }
}
.banner-rotate-text-box{
    position: relative;
    top: 153px;
    right: 134px;
    width: 300px;
    height: 270px;
    float: right;
    background-color: $color-white;
    box-shadow: 0 16px 14px -10px rgba(0, 0, 0, .58);
    -webkit-transform: rotate(13deg);
    -ms-transform: rotate(13deg);
    transform: rotate(13deg);
    @media only screen and (max-width: 1024px) {
      top:123px;
    }
    .banner-text{
            position: relative;
            font-size: 16px;
            position: absolute;
            left: 90px;
            top: 55px;
            color: $color-medium-black;
            line-height: 26px;
            text-align: center;
            font-weight: 400;
            }
    p{
        position: absolute;
        top: 86px;
        left: 66px;
        width:100%;
        color: $color-cyan-light;
        font-weight: bold;
        left: 0px;
        font-size: 24px;
        text-align: center;
    }
    .internal-link{
        position: absolute;
        left: 50%;
        bottom: 70px;
        padding: 4px 10px 4px 13px;
        color:$color-white;
        font-size: 16px;
        z-index: 2;
        display: block;
        margin-right: auto;
        margin-left: auto;
        transform: translate(-50%, 0px);
        transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 200ms cubic-bezier(.645, .045, .355, 1);
        font-family: Roboto, sans-serif;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        cursor: pointer;
    }
}

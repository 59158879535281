.teaser-quotes{
	position: relative;
    display: block;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    @media only screen and (max-width: 476px) {
        width: 100%;
        padding-left: 0px;
    }
    p{
	    display: block;
	    width: 93%;
	    margin: 0px auto 20px;
	    padding-bottom: 20px;
	    float: none;
	    font-size: 26px;
	    line-height: 35px;
	    font-weight: 300;
    	text-align: justify;
    	color: $color-medium-black;
    	&:after{
    		position:absolute;
    		content:'"';
    		width: 20px;
    		height: 20px;
    		font-size: 50px;
		    font-weight: 900;
		    margin-left: 15px;
		    margin-top: 10px;
    	}
		@media only screen and (max-width: 476px) {
                font-size:24px;
                line-height: 30px;
             }

    }
    &__quote{
		    position: absolute;
		    top: -10px;
		    float: left;
		    left:10px;
		    color: $color-medium-black;
		    font-size: 50px;
		    font-weight: 900;
             @media only screen and (max-width: 476px) {
                left: -5px;
             }
    }
    &__quote-right{
			position: absolute;
		    margin-left: 15px;
		    color: $color-medium-black;
		    font-size: 50px;
		    font-weight: 900;
    }
    .personal-information{
    		position: relative;
		    z-index: 1;
		    display: block;
		    margin-left: 29px;
		    padding-top: 15px;
		    float: left;
		    border-top: 1px solid $color-medium-black;
		    font-size: 16px;
		    line-height: 19px;
				@media only screen and (max-width: 1024px) {
				margin-bottom: 30px;
			}
    	span{
    		display: block;
    		font-weight: 400;
    	}
    	.additional-information{
    			font-size: 13px;
    			font-weight: 300;
    		}
    }

}

.finanzmeldungen-news{
  text-align: left;
.latest-news{
    &__list{
        list-style: none;
        padding: 0;
        padding-top: 50px;
        }


    &__title{
        position: relative;
        color: $color-cyan-light;
        font-size: 20px;
        line-height: 25px;
        font-weight: 300;
        text-align: left;
        cursor: pointer;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
          line-height: 20px;
        }
    }
    &__item{
        &:last-child{
            padding-top: 25px;
            border-top:1px solid $border-color-light-gray;
            margin-top:25px;
        }

    }
    }
     .news-list-date{
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 300;
        }

     .news-art{
                padding-left: 10px;
                font-size: 12px;
                color: $primary-font-color;
                font-weight: 500;
                text-transform: uppercase;
                cursor: pointer;
                &:hover{
                    text-decoration:underline;
                }
        }
        h5{
                font-size: 20px;
                line-height: 25px;
                font-weight: 300;
            a{
                &:hover{
                    color:$color-cyan;
                    text-decoration: underline;
                }
            }
        }
}

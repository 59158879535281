.teaser-career{
   display: flex;
   align-items: center;
   width: 80%;
   margin: 0 auto;
   .module-link__btn{
            	  margin-top: -47px;
            	  padding: 0px 40px 0px 17px;
            	  font-size: 24px;
                line-height: 47px;
                font-weight: 300;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
                -webkit-transition: color 300ms ease;
                transition: color 300ms ease;
                text-transform: inherit;
                top:inherit;
    &:before {
                margin-left: 10px;
                font-family:$icon-font;
                font-size: 13px;
                font-weight: 800;
                position: absolute;
                content: '-';
                width: 25px;
                height: 25px;
                right: 10px;
            }
   }
   .carrer-text{
               	position: relative;
                top: 16px;
                display: block;
                width: 80%;
                margin-right: auto;
                margin-left: auto;
                float: none;
                color: $color-medium-black;
                line-height: 22px;
                font-weight: 300;
                text-align: center;
                text-decoration: none;
                @media only screen and (max-width: 800px){
                  top:0;
                  padding-top:16px;

                }
    &:hover{
                color:$color-cyan-light;
        }
   }
   img{
               	border-bottom: 1px solid $border-color-light-gray;
                box-shadow: 0 8px 10px -14px rgba(0, 0, 0, .8);
                max-width: 100%;
                width: 100%;
   }

  a{
    display: block;
    width: 100%;
  }

}

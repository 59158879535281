.search-results{
    .tx-indexedsearch-searchbox{
        display: none;
    }
    h1{
        margin-top: 100px;
    }
    .tx-indexedsearch-title{
        font-size: 20px;
        font-weight: normal;
    }
    .tx-indexedsearch-browsebox{
        margin-top: 30px;
    }
    .tx-indexedsearch-description{
        border-bottom: 1px solid $border-color-light-gray;
        padding-bottom: 50px;
        margin-bottom: 35px;
    }
    .tx-indexedsearch-redMarkup{
        color:$color-cyan-light;
        font-weight: normal;
    }
    padding-bottom:25px;   
}
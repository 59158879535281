.global-btn-wrapper {
    .module-link__btn {
        padding: 10px 15px 10px 15px;
        position: relative;
        display: inline-block;
        left: 0;
        -webkit-transform: translate(0, 0px);
        -ms-transform: translate(0, 0px);
        transform: translate(0, 0px);
        word-break: break-all;

    }
    .internal-link {
        .module-link__btn {
            padding: 10px 50px 10px 15px;
            &:before {
                margin-left: 10px;
                font-family:$icon-font;
                font-size: 13px;
                font-weight: 800;
                position: absolute;
                content: '-';
                width: 25px;
                height: 25px;
                right: 10px;
            }
        }
    }
    .external-link {
        .module-link__btn {
            padding: 10px 50px 10px 15px;
            &:before {
                margin-left: 10px;
                font-family: $icon-font;
                font-size: 13px;
                font-weight: 800;
                position: absolute;
                content: '\b4';
                width: 25px;
                height: 25px;
                right: 10px;
            }
        }
    }
}

.external-link {
        .module-link__btn {
            padding: 10px 50px 10px 15px;
            &:before {
                margin-left: 10px;
                font-family: $icon-font;
                font-size: 13px;
                font-weight: 800;
                position: absolute;
                content: '\b4';
                width: 25px;
                height: 25px;
                right: 10px;
            }
        }
    }

.hr-contact-container{
    background-color: $color-cyan-light;
    .contact-teaser{
        .contact-image{
            width: 210px;
            height: 210px;
            //margin-left: 35px;
            float: left;
            margin: 0 auto;
            border: 10px solid $color-white;
            border-radius: 100%;
            background-position: 50% 30%;
            background-size: cover;
            background-repeat: no-repeat;
            -webkit-transition: border 350ms cubic-bezier(.645, .045, .355, 1);
            transition: border 350ms cubic-bezier(.645, .045, .355, 1);
            cursor: pointer;
            overflow: hidden;
            
            @media only screen and (max-width: 1200px) {
              margin: 0 auto;
              float: none;
            }
            @media only screen and (max-width: 960px) {
              //margin-left: 120px;
            }
            @media only screen and (max-width: 800px) {
              margin: 0 auto;
              float: none;
            }

            @media only screen and (max-width: 600px) {
                display: inline-block;
                margin:0 auto;
                float: none;
            }
        }
        .contact-details{
            position: relative;
            height: 210px;
            padding-top: 39px;
            padding-left: 13%;
            float: left;
            text-align: center;
            @media (min-width:1200px) and (max-width: 1280px) {
              padding-left: 8%;
            }
            @media only screen and (max-width: 1200px) {
              width: 100%;
              padding:39px 0;
            }
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        ul{
            list-style: none;
            margin:0;
            padding: 0;
            margin-top: 20px;
            li{
                margin:0;
                padding: 0;
                padding-right: 10px;
                padding-left: 10px;
                transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
                color: $color-white;
                font-size: 16px;
                line-height: 36px;
                font-weight: 300;
                &:first-child{
                    border-bottom:1px solid hsla(0, 0%, 100%, .2);
                }
                a{
                    font-size: 16px;
                    line-height: 36px;
                    font-weight: 300;
                    color: $color-white;
                    text-decoration: none;
                    cursor: pointer;
                }
                span{
                        position: relative;
                        top: 1px;
                        margin-right: 10px;
                        font-family: Icomoon, sans-serif;
                        font-size: 17px;
                }
                &:hover{
                    background: $color-medium-black;
                    color: $color-cyan-light;
                    a{
                      color: $color-cyan-light;
                    }
                }
            }
        }
        &__name{
            color: $color-white;
            font-size: 18px;
            font-weight: 400;
        }

        &__position{
            color: $color-medium-black;
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;

        }

        }
        @media only screen and (max-width: 476px) {
                text-align:center;
            }
    }
    .thema-title-top{
        background: $cyan-hover;
    }
}

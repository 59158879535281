// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

/**
Konrad Haenel - konrad.haenel@eqs.com   asd

To make life easier for you and everyone else working on this project, please follow this advice:

- Don't directly include styles in this file. Use imported files from ./lib for that.
- Don't directly copy dependencies into lib. Use bower to install them.
- Use multiple imported files, don't cram all styles into one giant file.
- Seperate files sensibly, so that related concepts are close to one another
  This point needs some explanation:
  BAD:  Separating out mediaqueries. If you need to change one element, you now have to look in multiple files.
  GOOD: Separating out style for tables. <table> is a complex element that is used on many pages and doesn't
        concern other elements. A file containing everything needed to style tables can be very helpful.
  Think about what an optimal unit of work is and put that in one scss file.

  Read more about SCSS here: http://sass-lang.com/
*/
@import "lib/reset";
@import "lib/fonts";
@import "lib/vars";
@import "lib/layout";

//header
@import "header/global-wrapper";
@import "header/header-sticky-wrapper";
@import "header/header-wrapper";
@import "header/logo";
@import "header/meta-navigation";
@import "header/main-navigation";
@import "header/search";
@import "header/top-contact";
@import "header/leftside-navigation";
@import "header/leftside-navigation-color";
@import "header/swiper";
@import "header/slider-text-wrapper";
@import "header/product-finder";
@import "header/contact-sliding-wrap";
@import "header/sub-menu-wrapper";
@import "header/mobile-navigation";
@import "header/mobile-menu";
@import "header/mobile-address-holder";
@import "header/mobile-menu-search";
@import "header/go-top-icon";
@import "header/mobile-header-navigation";
@import "header/submenu-teaser-career";
@import "header/submenu-youtube-video-full";
@import "header/submenu-teaser-image-headtext";


@import "global/internal-link";
@import "global/thema-title-top";
@import "global/btn-wrapper";
@import "global/module-link";
@import "global/global-btn-wrapper";
@import "global/button-no-background";
@import "global/right-sticky-navigation";
@import "global/contact-rightpanel";
@import "global/meta-navigation-side-contact";
@import "global/scroll-wrapper";
@import "global/section-border-bottom";
@import "global/global-list-wrap";
@import "global/selectric";

@import "footer/footer";
@import "footer/footer-cat";
@import "footer/footer-bg-container";
@import "footer/footer-copyright";
@import "footer/social-box";
@import "footer/footer-link-icons";
@import "footer/cookies";
@import "footer/go-top-icon-main";


@import "module/animations";
@import "module/module-wrapper";
@import "module/stripe-container";
@import "module/home-product-container";
@import "module/big-image-text-module";
@import "module/image-text-module";
@import "module/home-seperation-container";
@import "module/teaser-news";
@import "module/finanzmeldungen-news";
@import "module/home-publications";
@import "module/teaser-text";
@import "module/swiper-pagination";

@import "module/landing-page-slider";
@import "module/inner-page-container";
@import "module/breadcrumb";
@import "module/module-subheading";
@import "module/product-container";
@import "module/banner-rotate-text-box";
@import "module/product-teaser-box";
@import "module/teaser-image";
@import "module/home-product";
@import "module/image-links-container";
@import "module/financial-calender-container";
@import "module/accordion";
@import "module/contact-teaser";
@import "module/hr-contact-container";
@import "module/module-btn-wrapper";
@import "module/map-pattern";
@import "module/map-container";
@import "module/teaser-career";
@import "module/teaser-quotes";
@import "module/bg-white-container";
@import "module/news-list-view";
@import "module/distributor-link";
@import "module/page-not-found";
@import "module/contenttable";
@import "module/directors-board";
@import "module/search-results";
@import "module/login-wrapper";
@import "module/tx-femanager";
@import "module/newsfilter";
@import "module/table-grid";
@import "module/latest-news";
@import "module/download-module";
@import "module/popup-window";
@import "module/popup-wrapper";
@import "module/link-box";
@import "module/module-download-box";
@import "module/tx-cal-controller";
@import "module/errors";
@import "module/product-finder-page";
@import "module/hicon";
@import "module/meta-cal-wrapper";
@import "module/meta-socialmedia-wrapper";

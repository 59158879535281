.cookies{
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    width: 100vw;
    align-items: center;
    background-color: rgba(44, 44, 44, .7);
    z-index: 1000;
    display: none;
    &__content{
        text-align: center;
        padding: 17px 79px 0px 79px;
        box-sizing: border-box;
        color: $color-white;
        @media only screen and (max-width : 768px){
                padding:0 0 0 0;
        }
    }
    .close-cookie{
        display: none;
    }


.cookie{
    &__content{
            position: static;
            left: -148px;
            top: 30px;
            width: auto;
            margin-right: 50px;
            float: left;
            color: #fff;
            font-size: 15px;
            line-height: 19px;
            font-weight: 400;
            text-align: justify;
            display: block;
            padding: 17px 0px;

    }
    &__button{
        position: relative;
        margin-top: 0px;
        float: left;
        transition: color 0.3s ease 0s;
        cursor: pointer;
        background-color: $color-cyan-light;
        color: #fff;
        font-weight: 300;
        letter-spacing: 0.5px;
        text-decoration: none;
        padding: 7px 12px;
        display: block;
        &:hover{
            color:$dark-gray;
        }
        @media only screen and (max-width:1280px) {
            margin-bottom: 15px;
        }
    }
    &:after{
        content: " ";
        display: table;
        clear: both;
    }

}
}

.slidedown{
    transform-style: preserve-3d;
    transition: transform 300ms;
    transform: translateX(0px)
    translateY(100%)
    translateZ(0px);
}

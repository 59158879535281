.popup-window{
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 999999;
        display: block;
        overflow: auto;
        width: 100vw;
        height: 100vh;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: rgba(44, 44, 44, .7);
        -webkit-transform: translate(-100%, 0px);
        -ms-transform: translate(-100%, 0px);
        transform: translate(-100%, 0px);
        overflow: hidden;
    &__slidein{
        transform-style: preserve-3d;
        display: block;
        transition: transform 400ms;
        transform: translateX(0px) translateY(0px) translateZ(0px);
    }
    &__slideout{
        transform-style: preserve-3d;
        display: block;
        transform: translateX(150%) translateY(0px) translateZ(0px);
        transition: transform 1000ms;
    }

    &__close{
        position: absolute;
        top: 0;
        right: 0px;
        padding: 12px 9px;
        background-color: $color-cyan-light;
        font-size: 30px;
        line-height: 25px;
        font-weight: 400;
        text-decoration: none;
        cursor: pointer;
        font-family: $icon-font;
        color: $primary-font-color;
    }
}

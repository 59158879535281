.directors-board {
	position: relative;
	//min-height:87vh;

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        &-item {
            width: $three-colmn-width;
            margin-right: 29px;
            margin-bottom: 60px;
            float: left;
            border: 1px solid $border-color-light-gray;
            background-image: url(../images/pattern-v5.png);
            background-position: 0px 0px;
            background-size: 5px;
						//min-height: 370px;

            &:nth-of-type(3n+0) {
                margin-right: 0px;
								@media only screen and (max-width: 1024px) {
									float: none;
									clear: both;
								}
            }
            .management-board {
                &__photo {
										@media only screen and (max-width: 768px) {
											min-height: 246px;
									}
									img{
									min-height: 290px;
									@media only screen and (max-width: 1024px) {
									width: 100%;
									}
									@media only screen and (max-width: 768px) {
									min-height: auto;
									}
									}
								}
                &__detail {
                	position: relative;
									padding-bottom: 60px;
                    &-name {
                        color: $color-cyan-light;
                        font-size: 18px;
                        margin-top: 20px;
                        padding-left: 20px;
                        margin-bottom: 0;
                    }
                    &-title {
                        font-size: 14px;
                        line-height: 20px;
                        padding-left: 20px;
                        font-weight: 400;
												margin-bottom: 0px;
                    }
                }
                &__open-wrap {
                    padding: 20px;
                    background-color: #00bac5;
                    position: absolute;
                    left: 0;
                    display: none;
                    margin-top: 0;
                    margin-right: 2px;
                    width: 99.6%;
										z-index: 3;
                    h3 {
                        color: $color-white;
                        font-size: 28px;
    					line-height: 34px;
    					font-weight: 300;
    					margin-bottom: 20px;
                    }
                    p {
                        font-weight: 300;
                        text-align: justify;
                        color: #fff;
                        font-size: 16px;
                        line-height: 25px;
                    }
                }
            }


            .icon-open{
			    &:before{
			    position: absolute;
			    right: 0px;
			    bottom: 0;
			    width: 50px;
			    float: right;
			    background-color: $color-cyan-light;
			    font-family: $icon-font;
			    color: $color-white;
			    font-size: 24px;
			    line-height: 47px;
			    font-weight: 300;
			    text-align: center;
			    content: '#';
			    }
            }

            .icon-close{
            	&:before{
            	position: absolute;
			    right: 0px;
			    bottom: 0;
			    width: 50px;
			    float: right;
			    background-color: $color-cyan-light;
			    font-family: $icon-font;
			    color: $color-white;
			    font-size: 24px;
			    line-height: 47px;
			    font-weight: 300;
			    text-align: center;
			    content: 'x';
					z-index: 10;
				}
            }

						@media only screen and (max-width: 1024px) {
							    width: 46%;
						}
						@media only screen and (max-width: 768px) {
							width: 46%;
							margin-right: 4%;
						}
						@media only screen and (max-width: 767px) {
							width: 100%;
							margin-right:0;
						}

						.overlay {
    				opacity: .2;
    			pointer-events: none;
					}
        }
    }
    .board-details{
    	display: block;
    }


}


.directors-board__list-item.overlay {
opacity: 1;
pointer-events: none;
position: relative;
&:after{
	left: 0;
	top: 0;
	background-color: hsla(0, 0%, 100%, .59);
	background-image: url(../images/pattern-v5.png);
	background-position: 0px 0px;
	background-size: 5px;
	width: 100%;
	height: 100%;
	position: absolute;
	content: '';
}
}

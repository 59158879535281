.product-teaser-box{
    .teaser-image{
        padding-right: 0px;
        padding-left: 0px;
        border-top: 1px solid $border-color-light-gray;
        border-right: 0px solid $border-color-light-gray;
        border-left: 1px solid $border-color-light-gray;
        border-bottom: 1px solid $border-color-light-gray;
        background-color: $color-white;
        cursor: pointer;
        padding-bottom: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display:         flex;
        min-height: 410px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 0;
        }
        @media only screen and (max-width: 990px) {
          min-height: 450px;
        }
        @media only screen and (max-width: 468px) {
          min-height: auto;
        }
        @media only screen and (max-width: 768px) {
          border-right: 1px solid $border-color-light-gray;
          border-left: 1px solid $border-color-light-gray;
          margin-bottom: 60px;
        }
        &:nth-of-type(2) {

          @media only screen and (max-width: 990px) {
              border-right: 1px solid $border-color-light-gray;
              border-right: 1px solid $border-color-light-gray;
          }

          @media only screen and (max-width: 768px) {
            border-right: 1px solid $border-color-light-gray;
            border-left: 1px solid $border-color-light-gray;
          }

       }
         &:nth-of-type(3) {
        border-right: 1px solid $border-color-light-gray;
            @media only screen and (max-width: 990px) {
                clear: both;
                border-top: 0;
                border-right: 0;
            }
            @media only screen and (max-width: 768px) {
              border-right: 1px solid $border-color-light-gray;
              border-left: 1px solid $border-color-light-gray;
              border-top: 1px solid $border-color-light-gray;
            }
        }
        &:nth-of-type(4) {
            border-top: 0px;
            clear: both;
            @media only screen and (max-width: 990px) {
                clear: none;
                border-right: 1px solid $border-color-light-gray;
                border-top: 0px solid $border-color-light-gray;
            }
            @media only screen and (max-width: 768px) {
                clear: none;
                border-right: 1px solid $border-color-light-gray;
                border-top: 1px solid $border-color-light-gray;
            }
        }
        &:nth-of-type(5) {
            border-top: 0px;
            border-right: 1px solid $border-color-light-gray;
            @media only screen and (max-width: 990px) {
              clear: both;
              border-top: 0px solid $border-color-light-gray;
            }
            @media only screen and (max-width: 768px) {
                clear: none;
                border-right: 1px solid $border-color-light-gray;
                border-top: 1px solid $border-color-light-gray;
            }

        }

        @media only screen and (max-width: 576px) {
            padding-bottom: 0px;
            background-color: hsla(0, 0%, 91%, .5);
        }


        img{
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;
                margin-top: 45px;
                @media only screen and (max-width: 768px) {
                   height: auto;
                   width: auto;
                }
                 @media only screen and (max-width: 576px) {
                    display: none;
                 }
        }
        &__text{
            margin-top: 10px;
            padding-right: 35px;
            padding-left: 35px;
            color: $color-medium-black;
            line-height: 22px;
            font-weight: 300;
            text-align: center;
            text-decoration: none;
            &:hover{
                color:$color-cyan-light;
            }
             @media only screen and (max-width: 576px) {
                padding: 20px 10px 10px 17px;
                text-align:left;
            }
        }
        a{
            display: block;
            @media only screen and (max-width: 768px) {
              width: 100%;
              text-align: center;
            }

        }
        .module-link__btn{
             padding: 10px 50px 10px 15px;
             &:before {
                margin-left: 10px;
                font-family:$icon-font;
                font-size: 13px;
                font-weight: 800;
                position: absolute;
                content: '-';
                width: 25px;
                height: 25px;
                right: 10px;
            }
            @media only screen and (max-width: 576px) {
                position:relative;
            }
        }
    }
    @media only screen and (max-width: 476px) {
      padding-bottom:40px;
   }
}

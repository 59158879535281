.swiper-pagination{
    width: 100%;
    height: 40px;
    bottom: 0;
    text-align: center;
    .swiper-pagination-bullet{
    	position: relative;
	    display: inline-block;
	    width: 1em;
	    height: 1em;
	    background-color: rgba(34,34,34,0.6);
	    cursor: pointer;
	    margin: 0 3px .5em;
	    transition: background-color 100ms,color 100ms;
	    &-active{
	    	background: $bullet-color;
	    }
    }
}
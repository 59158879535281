.image-links-container{
	
	img{
		width: 100%;
    	margin-bottom: 12px;
    	border: 10px solid $border-color-light-gray;
    	max-width: 100%;
    	vertical-align: middle;
    	display: inline-block;
	}
	&__imagecaption{
		position: relative;
		p{
		font-size: 14px;
   	 	font-style: italic;
    	font-weight: 400;
    	&:after{
    			content:'';
			    width: 25px;
			    height: 5px;
			    margin-bottom: 35px;
			    float: left;
			    clear: left;
			    background-color: $color-medium-black;
			    position: absolute;
			    top: 22px;
    			left: 2px;
    		}
		}
	}
}
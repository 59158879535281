.footer{
    background-color: $dark-gray;
    bottom: 0;
    clear: left;
    float: left;
    height: 420px;
    left: 0;
    padding-top: 40px;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 0;
    color: $color-white;
    opacity: 1;
@media only screen and (max-width : 1080px){
    height: 430px;
}
@media only screen and (max-width : 990px){
    position:relative;
    padding-left: 35px;
    padding-right: 35px;
    height:inherit;
    padding-bottom:100px;
    overflow: hidden;
}

@media only screen and (max-width : 960px){
    padding-left: 35px;
    padding-right: 35px;
    position:relative;
}
@media only screen and (max-width : 768px){
    padding-left: 35px;
    padding-right: 35px;
    position:relative;
    padding-bottom:150px;
}
 @media only screen and (max-width : 476px){
    padding-right: 20px;
    padding-left: 20px;
 }
}
.footer-show{
    opacity: 1;
}

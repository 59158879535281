.sub-menu-wrapper {
    display: block;
    @media only screen and (max-width: 990px) {
        display: none;
    }
    .sub-menu-wrapper-holder {
        position: fixed;
        left: 50%;
        top: 116px;
        right: 0px;
        bottom: 125px;
        width: 100%;
        height: 0px;
        overflow: hidden;
        max-width: 1600px;
        background-color: $color-white;
        background-image: none;
        background-size: auto;
        -webkit-transform: translate(-50%, 0px);
        -ms-transform: translate(-50%, 0px);
        transform: translate(-50%, 0px);
        transition: height 400ms;
        z-index: 3;

    }
    &__menu-wrapper {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 1;
        &:first-child{
          .white-container{
            //padding-left: 30px;
          }
        }
        .white-container{
          padding-left: 6px;
          padding-right: 0px;
        }
    }

    .open {
        display: block;
        height: 400px;
        opacity: 1;
        transition: height 400ms;
        transition: all 0.5s;
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
    }
    .slideup {
        top: 70px;
    }
    &__each {
        padding-top: 80px;
        h1 {
            width: 100%;
            margin: 0;
            margin-bottom: 26px;
            padding-left: 0px;
            color: $color-cyan;
            font-size: 30px;
            font-weight: 300;
            letter-spacing: 0px;
            text-transform: uppercase;
            margin-left: 0px;

        }
        .text-body {
            padding-right: 20px;
            padding-left: 0px;
        }
        .internal-link {
            padding-right: 20px;
            padding-left: 0px;
            margin-top: 40px;
            display: inline-block;
        }
        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            width: 100%;
            padding-right: 20px;
            padding-left: 0px;
            float: left;
            li {
                width: 100%;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 15px;
                float: left;
                clear: left;
                border-bottom: 1px solid rgba(44, 44, 44, .11);
                -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 250ms cubic-bezier(.645, .045, .355, 1);
                transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 250ms cubic-bezier(.645, .045, .355, 1);
                color: $primary-font-color;
                font-weight: 400;
                letter-spacing: 0px;
                text-decoration: none;
                cursor: pointer;
                a {
                    color: $primary-font-color;
                    display: block;
                }
                &:hover {
                    background-color: $color-cyan;
                    a {
                        color: $color-white;
                    }
                }
            }
            .active{
              background-color: $color-cyan;
              a {
                  color: $color-white;
                  display: block;
              }
            }
        }
        .container {}

        .image-holder{
      img{
            margin-top: -50px;
            width: 70%;
      }

}
> .white-container{
  padding-left: 20px;
}
    }
    .fade-in {
        display: block;
        opacity: 1;
        transition: opacity 400ms;
        animation-delay: 2s;
        z-index: 1000;
    }
  .module-headline-text{
    //margin-left: -10px;
  }
  .global-btn-wrapper{
    //margin-left: -10px;
  }
}

.mobile-address-holder{
    position: relative;
    width: 100%;
    height: 167px;
    margin-left: 0px;
    padding: 30px 35px 35px;
    float: left;
    clear: left;
    border-bottom: 1px solid hsla(0, 0%, 85%, .3);
    background-color: $color-medium-black;   
    background-image: linear-gradient(180deg, rgba(44, 44, 44, .83), rgba(44, 44, 44, .83)), url(../images/mobile-pattern.png);
    background-size: auto, 5px;
    opacity: 1;
    @media only screen and (max-width : 486px){
    height: 240px;
}
    h1{
        color: $color-cyan;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        padding: 0;
        margin: 0;        
    }
    .left-column{
            width: 50%;
            float: left;
            margin-top: 20px;
            margin-bottom: 35px;
            color: $color-white;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
             @media only screen and (max-width : 486px){
                width: 100%;
                float: none;
             }
        }
        .right-column{
            width: 50%;
            float: left;
            margin-top: 20px;
            margin-bottom: 35px;
            color: $color-white;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            border-left: 1px solid hsla(0, 0%, 91%, .17);
            padding-left: 35px;
            .mobile-menu-contact{
              color:$color-cyan;  
            }
            span{
                font-family: $icon-font;
                position: relative;
                top: 1px;
                margin-right: 5px;
                font-size: 17px;
                margin-right: 10px;
            }
            .mobile-menu-contact:last-child{
                    border-top: 1px solid rgba(0, 186, 197, .49);
                    margin-top: 3px;
                    padding-top: 2px;
                    display: inline-block;
                    @media only screen and (max-width : 486px){
                        display: block;
                    }
            }
            @media only screen and (max-width : 768px){
                    padding-left: 20px;
            }
            @media only screen and (max-width : 486px){
                width: 100%;
                float: none;
                border-left:0;
                padding-left: 0px;
             }
        }
}
.teaser-image{
        border: 1px solid $border-color-light-gray;
        height: 100%;
        background: $color-white;
        @media only screen and (max-width: 1024px) {
          //min-height: 350px;
          //margin-bottom: 40px;
          }
          @media only screen and (max-width: 990px) {
            height: auto;
            margin-bottom: 40px;
          }
          @media only screen and (max-width: 768px) {
            min-height: 300px;
          }

        @media only screen and (max-width: 767px) {
          margin-left: 0px;
          margin-right: 0px;
          height: auto;
        }
        @media only screen and (max-width: 576px) {
            padding-bottom: 0px;
            background-color: hsla(0, 0%, 91%, .5);
            min-height: auto;
        }
        &__text{
                color: $color-medium-black;
                padding-left: 30px;
                padding-right: 30px;
                margin-top: 14px;
                text-align: justify;
                font-weight: 300;
                line-height: 25px;
                font-size: 16px;
                @media only screen and (max-width:1024px) {
                padding-right: 0;
                padding-left: 0;
                }
                p{
                  text-align: center;
                }
                @media only screen and (max-width: 576px) {
                   padding: 20px 10px 10px 17px;
                   text-align:left;
               }
               &:hover{
                 color:$color-cyan-light;
               }
        }
        img{
                //height: 60%;
                left: 0;
                position: relative;
                right: 0;
                top: 0;
                width: auto;
                display: inline-block;
                max-width: 100%;
                vertical-align: middle;
                max-width: 100%;
                width: 100%;
                //margin-top: 40px;
                @media only screen and (max-width:1024px) {
                  height: 50%;
                }
                @media only screen and (max-width: 576px) {
                   display: none;
                }
        }

        .module-link__btn{
          @media only screen and (max-width: 576px) {
              position:relative;
          }
        }
}

.teaser-news {
    background-color: $color-white;
    border: 1px solid $border-color-light-gray;
    box-shadow: none;
    height: 100%;
    text-align: center;
    @media only screen and (max-width: 990px) {
        height: auto;
        margin-bottom: 40px;
    }
    @media only screen and (max-width: 628px) {
        min-height: 360px;
    }
    .list-news {
        padding-left: 30px;
        padding-right: 30px;
        .latest-news {
            &__date {
                margin-bottom: 5px;
                font-size: 12px;
                font-weight: 300;
                display: block;
                .news-art {
                    padding-left: 10px;
                    color: $color-medium-black;
                    font-weight: 500;
                    text-transform: uppercase;
                    cursor: pointer;
                    &:hover{
                        text-decoration:underline;
                    }
                }
            }
            &__title {
                position: relative;
                color: $color-cyan-light;
                font-size: 20px;
                line-height: 25px;
                font-weight: 300;
                text-align: left;
                cursor: pointer;
            }
            &__list {
                list-style: none;
                text-align: left;
                margin: 0;
                padding: 0;
                padding-top: 60px;
            }
            &__item {
                padding-bottom: 15px;
                margin-bottom: 25px;
                border-bottom: 1px solid $border-color-light-gray;
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

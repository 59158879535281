.slider-text-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100vh;
    white-space: normal;
    text-align: left;
    &__slider-text-wrapper-container {
        left: auto;
        top: 40vh;
        right: 0;
        bottom: auto;
        position: absolute;
        z-index: 1000;
        display: block;
        margin-bottom: 11vw;
        white-space: normal;
        text-align: left;
        font-weight: 300;
        @media only screen and (max-width: 990px) {
            top: 16vh;
            right: 0;
        }
        
        &__headline {
            position: relative;
            z-index: 1;
            padding: 10px 30px;
            background-color: $color-white;
            box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, .23);
            color: $color-medium-black;
            font-size: 2.5rem;
            letter-spacing: 0.5px;
            text-decoration: none;
            text-transform: none;
            cursor: default;
            clear: both;
            margin: 0;
            font-weight: 100;
            @media only screen and (max-width: 990px) {
                font-size: 2.5rem;
                font-weight: 100;
                letter-spacing: 0.5px;
                padding-right: 30px;
            }
            @media only screen and (max-width: 767px) {
                padding: 10px 15px;
                font-size: 1.5rem;
                float: right;
                padding-right: 60px;
            }
            a {
                color: $color-medium-black;
            }

            @media only screen and (max-width: 526px) {
                line-height: 20px;
                max-width:60%;
            }


        }
        &__sub-headline {
            z-index: 0;
            display: block;
            margin-top: 10px;
            margin-left: 30px;
            padding: 20px;
            background-color: $color-medium-black;
            font-family: Roboto, sans-serif;
            color: $color-white;
            font-size: 16px;
            line-height: 0px;
            font-weight: 300;
            letter-spacing: 0.5px;
            text-transform: none;
            cursor: default;
            float: left;
            a {
                color: $color-white;
            }
            @media only screen and (max-width: 990px) {
                padding: 20px;
                font-size: 14px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 14px;
                padding:15px;
                margin-top:5px;
                clear: both;
                line-height:0px;
            }
            @media only screen and (max-width: 526px) {
                line-height: 20px;
                max-width:60%;
                float:right;
            }
        }
        &__slider-bodyText {
            position: relative;
            display: block;
            width: 656px;
            margin-left: 30px;
            padding-top: 50px;
            clear: left;
            color: $color-medium-black;
            text-align: justify;
            @media only screen and (max-width: 990px) {
                right: 78px;
                width: 400px;
                margin-top: 138px;
                float: right;
            }
            @media only screen and (max-width: 990px) {
                margin-top: 0px;
            }
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}
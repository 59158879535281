.hicon{
  @media screen and (max-width: 768px){
  background-image:url("../images/scroll.png");
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat:no-repeat;
  background-position:center;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
}
}

.footer-copyright {
    background-color: $very-dark-grey;
    bottom: 0; //height: 60px;
    left: 0;
    position: absolute;
    right: 0;
    @media only screen and (max-width: 460px) {
        height: 140px;
    }
    .copyright-text {
        cursor: pointer;
        font-size: 14px;
        font-weight: 300;
        margin-right: 20px;
        text-decoration: none;
        transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        padding-top: 20px;
        display: inline-block;
        float: left;
        @media only screen and (max-width: 1080px) {
            float: none;
            font-size: 13px;
            margin-bottom: 0px;
        }
    }
    .copyright-list {
        list-style: none;
        padding-top: 20px;
        @media only screen and (max-width: 1080px) {
            padding-top: 0;
            padding-left: 0px;
            margin: 0;
        }
        li {
            display: inline-block;
            a {
                cursor: pointer;
                font-size: 14px;
                font-weight: 300;
                margin-right: 20px;
                text-decoration: none;
                transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                color: $color-cyan;
                &:hover{
                  text-decoration: underline;
                }
                @media only screen and (max-width: 460px) {
                    font-size: 13px;
                }
            }
        }
    }
}

.product-finder-page {
  #nab_search_results .table-wrapper {
    clear: both;
    width: 100%;
  }
  padding: 0 0 60px;

  &__text {
    padding: 0 10px 10px;
    line-height: 30px;

    .spacer {
      width: 38px;
      display: inline-block;
    }

    .compare-icon {
      display: inline-block;
      line-height: 40px;
      width: 42px;
      height: 40px;
      background-color: #73D9DF;
      transition: background-color 250ms ease;
      color: #fff;
      font-size: 23px;
      //cursor: pointer;
      position: absolute;
      text-align: center;
      padding: 10px 0 0;
      top: 27px;

      &::after {
        float: right;
        content: ":";
        font-family: 'Produkt-Font', sans-serif;
        font-size: 19px;
        font-weight: 800;
        width: 40px;
        height: 40px;
        margin: 0 1px;
        line-height: 40px;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &__selects {
    .selectric-wrapper {
      width: 25%;
      margin: 0;
      float: left;
      padding: 0 10px;
      @media only screen and (max-width: 768px) {
        width: 50%;
        margin-bottom: 20px;
      }
      @media only screen and (max-width:320px) {
        width: 100%;
      }

      .selectric {
        border: 1px solid $color-cyan;
        border-radius: 5px;
        text-align: center;

        .button {
          display: block;
          background: $color-cyan;
          color: $color-white;
          width: 32px;

          &:after {
            border: 7px solid transparent;
            border-top-color: $color-white;
            top: 7px;
          }
        }
      }

      .selectric-items {
        left: 10px;
        padding: 0 0 0 15px;
        border-bottom: 0;
        border: 1px solid $color-cyan;

        li {
          padding-left: 30px;
          position: relative;
          color: $primary-font-color;
          font-size: 13px;
          border-bottom: 0;

          &:before {
            margin-left: 10px;
            font-family: $icon-font;
            font-size: 13px;
            font-weight: 800;
            position: absolute;
            content: ' ';
            width: 15px;
            height: 15px;
            border-radius: 15px;
            left: -2px;
            top: 15px;
            border: 4px solid $color-cyan;
          }
        }

        .selected {
          &:before {
            background: $color-cyan;
          }
        }

        .selectric-group-label {
          padding-left: 10px;
          border-bottom: 1px solid $border-grey;

          &:before {
            display: none;
          }
        }
      }

      .selectric-group {
        border: 0;
      }
    }
  }

  &__search-results {
    padding: 25px 10px;
    clear: both;

    &--tag {
      background: $color-black;
      display: inline-block;
      color: white;
      padding: 10px;
      width: 20%;
      border-right: 1px solid $color-white;
      border-bottom: 1px solid $color-white;
      position: relative;
      font-size: 13px;
      @media only screen and (max-width: 768px) {
        width: 50%;
      }
      @media only screen and (max-width:320px) {
        width: 100%;
      }

      a {
        color: $color-white;
        position: absolute;
        right: 5px;
        top: 17px;
        font-size: 15px;
        font-family: $icon-font;
      }
    }
  }

  &__your-selection {
    padding: 25px 0;

    ul {
      list-style: none;
      position: relative;

      div {
        float: left;
        width: 25%;
        @media only screen and (max-width: 768px) {
          width: 50%;
        }
        @media only screen and (max-width: 576px) {
          width: 100%;
        }

        a {
          color: #ffffff;
        }

        a.p_compare::after,
        a.p_download::after {
          float: right;
          font-family: 'Produkt-Font', sans-serif;
          font-size: 19px;
          font-weight: 800;
          width: 40px;
          height: 40px;
          margin: 0 1px;
          line-height: 40px;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        a.p_compare::after {
          content: ":";
        }

        a.p_compare.active::after {
          content: '_';
        }

        a.p_download::after {
          content: ';';
        }
      }

      li {
        background: $color-cyan;
        display: inline-block;
        color: $color-white;
        //padding: 10px;
        width: 100%;
        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        position: relative;
        font-size: 13px;
        line-height: 40px;
        padding-left: 10px;
        // @media only screen and (max-width: 768px) {
        //   width: 100%;
        // }
        // @media only screen and (max-width:320px) {
        //   width: 100%;
        // }
        &:after {
          margin-left: 10px;
          font-family: $icon-font;
          font-size: 13px;
          font-weight: 800;
          position: absolute;
          // content: '-';
          width: 25px;
          height: 25px;
          right: -2px;
          top: 10px;
        }
      }
    }
  }

  .p_compare,
  .p_download {
    display: inline-block;
    line-height: 40px;
    width: 42px;
    height: 40px;
    background-color: #73D9DF;
    transition: background-color 250ms ease;
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    position: absolute;
    right: 0;
    text-align: center;
    padding: 10px 0 0;

    &:hover {
      background-color: #00bac5;
      border-left: 0 solid #73D9DF;
    }
  }

  .p_compare {
    right: 42px;
    border-right: 0;
    margin-right: 0;
    border-right: 1px solid #00bac5;

    &:hover {
      border-left: 1px solid #73D9DF;
    }
    @media only screen and (max-width: 667px) {
      display: none;
    }
  }

  .p_compare.active {
    background-color: #1DBAC4;
    border-left: 1px solid #7adae0;
  }

  .compare_table_arrow {
    width: 40px;
    height: 40px;
    background-color: #1DBAC4;
    transition: background-color 250ms ease;
    padding: 10px;
    font-family: $icon-font;
    position: absolute;
    top: 0;
    color: $color-white;
    left: -60px;
    text-align: center;
    line-height: 20px;

    &:hover {
      background-color: #73D9DF;
    }
  }
}

.product-finder-page__selects .selectric-wrapper .selectric-items li.disabled {
  display: none!important;
}

.product-finder__commonclose,
.removeFiltersSelection {
  background: #fff;
  height: 58px;
  line-height: 32px;
  display: inline-block;
  float: left;
  font-size: 20px;
  border: 2px solid #000;
  text-align: center;
  font-size: 13px;
  padding: 10px;
  margin-right: 1px;

  a {
    color: #000;
    font-family: $icon-font;
  }
}

.removeFiltersSelection {
  color: #000!important;
  float: none;
  margin-bottom: 10px;

  span {
    color: #000;
    font-family: $icon-font;
  }
}
.big-image-text-module {
    background-image: url("");
    background-position: 52% 18%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid $border-color-light-gray;
    box-shadow: none;
    height: 100%;
    @media only screen and (max-width: 990px) {
        height: 34em;
    }
    &__pattern-wrapper {
        padding-left: 30px;
        padding-right: 30px;
        background-color: $color-white;
        background-image: url("../images/pattern.png");
        background-position: 0 0;
        background-size: 5px auto;
        box-shadow: 0 11px 8px -5px rgba(0, 0, 0, 0.36);
        display: block;
        height: 218px;
        left: 0;
        position: absolute;
        right: 0;
        top: 216px;
        width: 100%;
        @media only screen and (max-width: 1100px) {
            height: auto;
            padding-bottom: 40px;
        }
        @media only screen and (max-width: 476px) {
        padding-left: 0px;
        padding-right: 0px;
        }
        .headline {
            color: $color-cyan-light;
            cursor: pointer;
            font-size: 26px;
            font-weight: 300;
            line-height: 25px;
            margin-top: 30px;
            padding: 4px 30px 4px 20px;
            position: relative;
            text-align: left;
            text-transform: uppercase;
            @media only screen and (max-width: 476px) {
            font-size: 25px;
            padding-right: 10px;
        }
        }
        .bodytext {
            color: $primary-font-color;
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 14px;
            text-align: justify;
            font-weight: 300;
            line-height: 25px;
            font-size: 16px;
            @media only screen and (max-width: 768px) {
                line-height: 18px;
                font-size: 14px;
            }
        }
    }
    @media only screen and (max-width: 990px) {
        margin-bottom: 60px;
    }
    .internal-link {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.module-wrapper{
    position: relative;
    @media only screen and (max-width: 768px) {
    	margin-bottom: 60px;
    }
    @media only screen and (max-width: 476px) {
    	margin-left:-15px;
    	margin-right:-15px;
    }
}

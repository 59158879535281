.global-list-wrap {
    ul {
        list-style: none;
        margin-left: 20px;
        li {
            &:before {
                content: "•";
                color: $color-cyan-light;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 19px;
                font-weight: 300;
    			text-align: justify;
            }
        }
    }
    ol {
        list-style: none;
        margin-left: 20px;
        counter-reset: li;
        li {
            counter-increment: li;
            &:before {
                content: counter(li);
                color: $color-cyan-light;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                margin-right: 1em;
            }
        }
    }
}

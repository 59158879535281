.social-box {
    text-align: right;
    width: 240px;
    float: right;
    @media only screen and (max-width: 460px) {
        width: 100%;
        float: left;
    }
    .teilen {
        color: $color-white;
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
        margin-right: 20px;
        padding-top: 20px;
        float: left;
    }
    a {
        color: $color-cyan;
        cursor: pointer;
        margin-top: 13px;
        font-family: Icomoon, sans-serif;
        font-size: 26px;
        line-height: 31px;
        padding-left: 12px;
        padding-right: 12px;
        text-decoration: none;
        transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        display: inline-block;
        &:hover{
          color: $color-white;
        }
    }
}
.share-links-mobile {
    display: none;
    @media only screen and (max-width: 460px) {
        display: block;
        width: 100%;
    }
}
.share-links {
    display: block;
    @media only screen and (max-width: 460px) {
        display: none;
    }
}
.desktop {
    text-align: right;
    width: 240px;
    float: right;
    @media only screen and (max-width: 460px) {
        display:none;
    }
}
.mobile {
    display: none;
    @media only screen and (max-width: 460px) {
        display: block;
        width: 100%;
    }
}

.leftside-navigation-color{
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    display: block;
    overflow: auto;
    border-top: 1px solid #d8d8d8;
    background-color: $color-cyan;
    opacity: 0;
    width: 0;
    -webkit-transform: translate(-100%, 0px);
    -ms-transform: translate(-100%, 0px);
    transform: translate(-100%, 0px);   
}

.leftside-slide-color-in{
    opacity: 1;
    width: 100%;
    transform-style: preserve-3d;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    -ms-transform: translateX(0px) translateY(0px) translateZ(0px);
    transition: transform 550ms;    
    -ms-transition: all 550ms;    
}

.leftside-slide-color-out{
    transform-style: preserve-3d;
    display: block;
    transform: translateX(150%) translateY(0px) translateZ(0px);
    transition: transform 1000ms;
}
.link-box {
    position: relative;
    margin-top: 6px;
    margin-bottom: 40px;
    padding: 36px 30px 39px;
    border: 1px solid $border-color-light-gray;
    background-color: $color-white;
    background-image: url(../images/pattern.png);
    background-position: 0px 0px;
    background-size: 5px;
    min-height: 210px;
    @media only screen and (max-width: 990px) {
    padding: 36px 15px 39px;
    }
    @media only screen and (max-width: 990px) {
    min-height: auto;
    }
    li {
        width: 100%;
        list-style: none;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid $border-color-light-gray;
        color: $color-cyan-light;
        text-decoration: none;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        position: relative;
        padding-right: 30px;
        word-break: break-all;
        a{
            color:$color-cyan-light;
            word-break: keep-all;
            display: block;
        }
        &:after{
            width:25px;
            height: 25px;
            content: '-';
            position: absolute;
            right: -8px;
            top: 5px;
            font-family: $icon-font;
            font-size: 13px;
            font-weight: 800;
        }
        &:last-child{
          border-bottom: 0;
        }
    }
}

.product-container{
	.module-wrapper{
		Width:33.33%;
		float: left;
		background-size:50% auto;
		background-repeat: no-repeat; 
		background-position: center;
		background-color: $color-white;	
		@media only screen and (max-width: 990px) {
			width: 50%;	
		}	
		@media only screen and (max-width: 768px) {
			width: 100%;
			float:none;
			margin-left: 0px;	
		}
        @media only screen and (max-width: 576px) {
        	margin-bottom: 20px;        	
        }	
	}
}
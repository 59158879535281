.internal-link {
    
    &:before{
        content:'';
    }
  	.module-link__btn{
		padding: 10px 50px 10px 15px;
		&:before{
        margin-left: 10px;
        font-family:$icon-font;
        font-size: 13px;
        font-weight: 800;
        position: absolute;
        content: '-';
        width: 25px;
        height: 25px;
        right: 10px;
    }
   }  
}


.go-top-icon-main{
		right: 0px;
    bottom: 60px;
    margin-bottom: 0px;
    position: fixed;
    z-index: 99999;
    width: 50px;
    padding-right: 0px;
    padding-left: 0px;
    background-color:$color-cyan-light;
		color: $color-white;
    font-size: 26px;
    text-align: center;
    cursor: pointer;
    transition: color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 600ms;
    opacity: 0;
    font-family: Icomoon, sans-serif;
    -webkit-transform: translateZ(0);
    height: 50px;
    line-height: 50px;
    font-weight: 300;
    letter-spacing: 1px;
    text-decoration: none;
		display: none;
		@media only screen and (max-width : 990px){
				display: block;
			}
    @media only screen and (max-width : 460px){
        bottom: 0;
    }
}
.go-top-icon-main-slide-in{
	opacity: 1;
	transition: color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 600ms;
}

.popup-global-wrapper{
    position: relative;
    overflow: auto;
    max-width: 1200px;
    padding-top: 120px;
    padding-bottom: 80px;
    background-color: $color-white;
    margin:0 auto;
    -webkit-transform: translate(-200%, 0px);
    -ms-transform: translate(-200%, 0px);
    transform: translate(-200%, 0px);

    &__slidein{
        transform-style: preserve-3d;
        display: block;
        transition: transform 300ms;
        transform: translateX(0px) translateY(0px) translateZ(0px);
    }
     &__slideout{
        transform-style: preserve-3d;
        display: block;
        transform: translateX(150%) translateY(0px) translateZ(0px);
        transition: transform 1000ms;
    }
    &__inner{
        width: 86%;
        margin:0 auto;
        background-image:url("../images/eclipse.gif");
        background-color: $color-white;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 150px 150px;
        overflow:auto;
        -webkit-overflow-scrolling:touch;
        @media only screen and (max-width: 1024px) {
        height: 500px;
        }
        iframe{
        border:0;
        height: 700px;
        width: 100%;
        }
    }
}

.login-wrapper{
    margin-top: 60px;
    margin-bottom: 80px;
    padding: 40px 20px;
    border: 1px solid $border-color-light-gray;
    background-image: url(../images/pattern.png);
    background-position: 0px 0px;
    background-size: 5px;
    input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}
    input[type="text"], input[type="password"]{
        margin-bottom: 10px;
        border: 0;
        border-bottom: 1px solid $border-grey;
        background-color: $color-white;
        font-weight: 400;
        color: $color-cyan-light;
        font-size: 15px;
        padding: 0;
        line-height: 40px;
        padding-left: 20px;
        width: 100%;
        &:focus {
            outline:none;
            outline-style: none;
            border-bottom: 1px solid $color-cyan-light;
        }
    }
    input[type="submit"]{
        margin-top: 40px;
        padding-bottom: 10px;
        -webkit-appearance: button;
        display: inline-block;
        padding: 9px 15px;
        border: 0;
        line-height: inherit;
        text-decoration: none;
        cursor: pointer;
        border-radius: 0;
        background-color: $color-cyan;
        color:$color-white;
        font-size: 16px;
        font-weight: 400;
        &:hover{
        	color: #2C2C2C;
        }        
    }
    .tx-felogin-pi1{
        margin-bottom: 15px;
    }
    .error{
         -moz-box-shadow:    inset 0 0 10px #FF0000;
        -webkit-box-shadow: inset 0 0 10px  #FF0000;
        box-shadow:         inset 0 0 10px  #FF0000;
    }

    .back-to-login{
        display: none;
    }
}


.femanager_fieldset{
	margin-right: -15px;
	margin-left: -15px;
}

.home-product {
    position: relative;
    z-index: 1;
    width: 100vw;
    padding: 80px 80px 80px 60px;
    padding-top: 0px;
    padding-bottom: 40px;
    @media only screen and (max-width: 1024px) {
        padding: 40px 30px 40px 30px;
        }
    @media only screen and (max-width: 768px) {
        padding: 0px 30px 0px 30px;
        }
     @media only screen and (max-width: 476px) {
            margin:0;
            padding:0px;
            width:100%;
        }
    &__wrapper {
        position: relative;
        width: 20%;
        padding-right: 30px;
        padding-left: 30px;
        float: left;
        @media only screen and (max-width: 1024px) {
            padding-right: 17px;
            padding-left: 17px;
        }
        @media only screen and (max-width: 768px) {
            padding-right: 5px;
            padding-left: 5px;
            height: 125px;
        }
        @media only screen and (max-width: 476px) {
            width:100%;
            margin-bottom:10px;
            height:auto;
        }

        &__column {
            position: relative;
            width: 100%;
            border-top: 1px solid rgba(44, 44, 44, .07);
            border-left: 1px solid rgba(44, 44, 44, .07);
            background-image: -webkit-linear-gradient(270deg, $color-white, $color-white);
            background-image: linear-gradient(180deg, $color-white, $color-white);
            box-shadow: 0 4px 7px -2px rgba(44, 44, 44, .25);
           @media only screen and (max-width: 768px) {

           }
           @media only screen and (max-width: 476px) {
             background:$color-cyan;
             width:80%;
             display:inline-block;
            }
            img {
                margin: 25px 0 0 0;
                 @media only screen and (max-width: 1140px) {
                    margin: 0px 0 0 0;
                 }
                 @media only screen and (max-width: 476px) {
                    display:none;
                 }
            }
            &__link {
                display: block;
                width: 100%;
                height: 100%;
            }
            &__text {
                display: block;
                transition: color 250ms cubic-bezier(.645, .045, .355, 1);
                font-family: $primary-font-family;
                color: $primary-font-color;
                font-size: 1vw;
                line-height: 1.3em;
                font-weight: 300;
                text-align: center;
                text-transform: none;
                cursor: pointer;
                padding: 25px 0 25px 0;
                &:hover{
                    color:$color-cyan-light;
                    @media only screen and (max-width: 476px){
                        color:$color-white;
                    }

                }

                &:after{
                    @media only screen and (max-width: 476px) {
                    margin-left: 10px;
                    font-family: $icon-font;
                    font-size: 13px;
                    font-weight: 800;
                    position: absolute;
                    content: '-';
                    width: 25px;
                    height: 25px;
                    right: 10px;
                    }
                }
                @media only screen and (max-width: 1140px) {
                 padding: 0 0 25px 0;
                }
                @media only screen and (max-width: 1024px) {
                padding: 10px 10px 10px 10px;
                font-size: 1.3vw;
                line-height: 1.1em;
                font-weight: 400;
            }

                @media only screen and (max-width: 476px){
                font-size: 13px;
                text-align: left;
                color:$color-white;
                padding: 12px 17px;
                }
            }
        }
    }
}
.clear {
    clear: both;
}

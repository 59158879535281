.contact-teaser{
        width:100%;
        height: 100%;
        padding-left: 35px;
        @media only screen and (max-width: 1280px) {
          padding-left: 0;
        }
        &-first{
	            border-right: 1px solid hsla(0, 0%, 100%, .2);
	            padding-left: 0;
              padding-right: 10%;
            @media only screen and (max-width: 990px) {
            	border-right: 0;
              padding-right: 0;
            }
        }

        @media only screen and (max-width: 990px) {
                    padding-left:0px;
                    padding-top: 20px;
       }
       @media only screen and (max-width: 600px) {
                   padding-left:0px;
                   padding-top: 20px;
                   text-align: center;
      }
}

.header-wrapper {
    position: static;
    z-index: 2;
    display: block;
    padding-top: 20px;
    background: $color-white;
    transform-style: preserve-3d;
    transition: transform 500ms;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    border-bottom: 1px solid $border-grey;
    margin-left: -15px;
    &__sticky {
        transform-style: preserve-3d;
        transition: transform 500ms;
        transform: translateX(0px) translateY(-45px) translateZ(0px);
        @media only screen and (max-width: 990px) {
            transform: translateX(0px) translateY(0px) translateZ(0px);
        }
        .logo {
            transform-style: preserve-3d;
            transition: transform 500ms;
            transform: translateX(0px) translateY(-40px) translateZ(0px);
            @media only screen and (max-width: 990px) {
                transform: translateX(0px) translateY(0px) translateZ(0px);
            }
        }
    }
}

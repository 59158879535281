.module-link {
	 &__btn{
   	text-decoration: none;
		position: relative;
    z-index: 2;
    display: block;
    position: absolute;
    left: 50%;
    padding: 10px 15px 10px 15px;
    background-color: $color-cyan;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    font-family: Roboto, sans-serif;
    color: $color-white;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    top:0;
    &:hover{
        color: $primary-font-color;
    }
		@media only screen and (max-width: 990px) {
			line-height: 25px;
		}
    }
}


a.module-link {
     &__btn{
    text-decoration: none;
    position: relative;
    z-index: 2;
    display: block;
    position: absolute;
    left: 50%;
    padding: 10px 50px 10px 15px;
    background-color: $color-cyan;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    font-family: Roboto, sans-serif;
    color: $color-white;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    &:before{
        margin-left: 10px;
        font-family: Icomoon, sans-serif;
        font-size: 13px;
        font-weight: 800;
        position: absolute;
        content: '-';
        width: 25px;
        height: 25px;
        right: 10px;
    }
    &:hover{
        color: $primary-font-color;
    }
    }
}

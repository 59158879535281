.module-thema-btn-wrapper{
	text-align: center;	
	margin-top: -100px;
	@media only screen and (max-width: 768px) {
		margin-top: -60px;
	}
}
.home-seperation-container{
	.module-thema-btn-wrapper{
		text-align: center;	
		margin-top: 0px;
	}
}

.hr-contact-container{
	.module-btn-wrapper{
		margin-top: -100px;
		text-align: center;	
	}
}
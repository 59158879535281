.video-module-wrapper {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  //margin-top: 25px;
  //height: 100%;
  .youtube-video {
    position: relative;
    display: block;
    clear: none;
    float: right;
    width: 100%;
    margin-bottom: 30px;

    img {
      @media only screen and (max-width: 990px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .youtube-caption {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    margin-bottom: 57px;
    padding: 7px 13px;
    float: left;
    clear: left;
    background-color: $color-white;
    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, .23);
    color: $color-cyan-light;
    font-size: 1vw;
    font-weight: 300;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    @media only screen and (max-width: 990px) {
      font-size: 16px;
    }
  }

  .youtube-caption-sub {
    position: absolute;
    bottom: 27px;
    z-index: 0;
    display: block;
    margin-left: 15px;
    padding: 13px;
    float: left;
    background-color: $color-medium-black;
    color: $color-white;
    font-size: 16px;
    line-height: 0;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: none;
    @media only screen and (max-width: 1280px) {
      padding: 8px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
      line-height: 15px;
      bottom: 10px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .video-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    width: 120px;
    height: 120px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 100%;
    background-color: hsla(0, 0%, 100%, .80);
    transform: translate(-50%, 0px) translate(0px, -50%);
    font-family: $icon-font;
    color: $color-medium-black;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -4.5px;
    text-decoration: none;
    z-index: 10;

    &:after {
      content: '?';
      position: absolute;
      left: 37%;
      top: 22%;
    }
    @media only screen and (max-width: 1024px) {
      width: 100px;
      height: 100px;
    }
  }
}
.right-sticky-navigation {
    position: fixed;
    top: 64%;
    right: -1px;
    z-index: 1000;
    width: 365px;
    float: right;
    border-top: 1px solid hsla(0, 0%, 100%, .11);
    border-left: 1px solid hsla(0, 0%, 100%, .11);
    background-color: hsla(0, 0%, 100%, .11);
    -webkit-transform: translate(311px, 0px);
    -ms-transform: translate(311px, 0px);
    transform: translate(311px, 0px);
    transition: transform 400ms ease 0s;
    @media only screen and (max-width: 1024px) {
        top: 58%;
    }
    .fixed-icon-holder {
        position: static;
        z-index: 2;
        margin-bottom: 1px;
        padding: 0px 15px;
        -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 300ms cubic-bezier(.645, .045, .355, 1);
        transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 300ms cubic-bezier(.645, .045, .355, 1);
        font-family: Icomoon, sans-serif;
        color: $color-white;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        width: 50px;
    }
    ul {
        list-style: none;
        position: relative;
        right: 15px;
        margin: 0;
        padding: 0;
        width: auto;
        li {
            position: relative;
            margin-bottom: 1px;
            height: 52px;
            background-color: $dark-gray;
            font-weight: 400;            
            a {
                background-color: $dark-gray;
                display: block;
                padding: 8px 14px;
                z-index: 10;
                position: absolute;
                color: $color-white;
                &:hover {
                    background: $color-cyan;
                    color: $color-white;
                }
            }
            &:before {
                background-color: #00bac5;
                height: 10px;
                position: absolute;
                right: 8px;
                top: 20px;
                transform: rotate(45deg);
                width: 10px;
                z-index: 99999;
                z-index: 2;
                transform: translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(45deg);
                transform-style: preserve-3d;
                transition: transform 200ms ease 0s;
                display: block;
                content: ' ';
                opacity: 0;
            }
            &:after {
                content: attr(data-title);
                text-align: left;
                font-family: $primary-font-family;
                background-color: $dark-gray;
                color: $color-white;
                float: left;
                font-size: 14px;
                padding: 4px 20px 4px 15px;
                position: static;
                text-decoration: none;
                left: 0px;
                position: absolute;
                top: 11px;
                line-height: 22px;
                opacity: 0;
                transform: translateX(100%) translateY(0px) translateZ(0px);
                transform-style: preserve-3d;
                transition: transform 350ms ease 0s;
                z-index: 0;
            }
            &:hover {
                background: $color-cyan;
                color: $color-white;
                &:after {
                    transform: translateX(-100%) translateY(0px) translateZ(0px);
                    transform-style: preserve-3d;
                    transition: transform 400ms ease 0s;
                    opacity: 1;
                }
                &:before {
                    transform: translateX(-6px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(45deg);
                    transform-style: preserve-3d;
                    transition: transform 200ms ease 0s;
                    -webkit-transition-delay: 0.2s;
                    /* Safari */
                    transition-delay: 0.2s;
                    opacity: 1;
                }
            }
        }
        .active {
            a {
                background: $color-cyan;
                color: $dark-gray;
                &:hover{
                    color:#fff;
                }
            }
        }
    }
    &__open {
        transform: translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1);
        transition: transform 400ms ease 0s;
    }
    @media only screen and (max-width: 990px) {
        display: none;
    }
}
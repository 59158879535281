.global-wrapper {
    position: relative;
    z-index: 10;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    //-webkit-transform-style: preserve-3d;
    //-moz-transform-style: preserve-3d; /** fixes non transitive 3d from parent and child **/
    transition: transform 750ms;
    height: auto;
}


.global-wrapper-slide {
    transform-style: preserve-3d;
    transition: transform 700ms, height 500ms;
    min-height: 100vh;
    transform: translateX(100%) translateY(0px) translateZ(0px);
    transition: transform 1000ms;
    height: auto;
}

.global-wrapper-slide-slidein{
    transform-style: preserve-3d;
    transition: height 500ms;
    height: 100vh;
    transform: translateX(-100%) translateY(0px) translateZ(0px);
    display: block;
    width: 100%;
}
.sliding-up{

}

h1{
    margin-top: 0px;
    margin-bottom: 40px;
    font-family:$primary-font-family;
    color: $color-cyan-light;
    font-size: 42px;
    line-height: 45px;
    font-weight: 300;
    text-transform: uppercase;
    margin: .67em 0;
    @media only screen and (max-width: 476px) {
        font-size: 35px;
        word-break: normal;
        }
}

h2 {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
    font-family:$primary-font-family;
    color: $color-cyan-light;
    font-size: 28px;
    line-height: 34px;
    font-weight: 300;
}

h3{
    margin-top: 50px;
    font-family:$primary-font-family;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
}
h4{
    margin-top: 50px;
    font-family:$primary-font-family;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
}

h5{
    margin-top: 50px;
    font-family:$primary-font-family;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
}

p{

}

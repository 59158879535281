.mobile-navigation{
    display:none;
    &__burger-menu{
        float: right;
        font-family: Icomoon,sans-serif;
        font-size: 24px;
        font-weight: 300;
        height: 50px;
        letter-spacing: 1px;
        line-height: 50px;
        padding-left: 13px;
        padding-right: 15px;
        position: relative;
        text-decoration: none;
        transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        @media only screen and (max-width: 476px) {
        padding-right: 0px;
    }
    }
    &__search{
    font-weight: 300;
    height: 50px;
    letter-spacing: 1px;
    line-height: 50px;
    padding-left: 13px;
    padding-right: 15px;
    position: relative;
    text-decoration: none;
    transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    color: $color-cyan;
    @media only screen and (max-width : 482px){
        display:none;
        padding-right: 0px;
    }
    }

    &__anderecolor{
       background-color: $border-grey;
       float: right;
       height: 30px;
       margin: 9px 18px 9px 9px;
       width: 1px; 
       @media only screen and (max-width : 482px){
        display:none;
    }
    }
    @media only screen and (max-width : 990px){
        display:block;
    }
}
.felogin_error{
    background: #ebccd1;
    color: #a94442;
    padding: 15px;
    margin-bottom: 25px;
}
.felogin_success, .femanager_success{
    background:#dff0d8;
    color: #3c763d;
    padding: 15px;
    margin-bottom: 25px;
}
.femanager_error{
    background: #ebccd1;
    color: #a94442;
    padding: 15px;
    margin-bottom: 25px;

        list-style: none;
        li{
            padding: 0 0 5px 0;
        }

}

.user-login-register__login-form label{
    display: none!important;
}

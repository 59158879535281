.news-list-view {
    padding-right: 30px;
    padding-left: 30px;
    .article {
        border-bottom: 1px solid $border-color-light-gray;
        padding-bottom: 30px;
    }
    .article:last-child {
        border-bottom: 0px solid $border-color-light-gray;
    }
}

.mobile-header-navigation{
   display: block;
   text-align: left;
   margin: 0;
   padding: 0;
        li{
            display: inline-block;
            float: left;
            margin-left: 12px;
            @media only screen and (max-width: 640px) {
                        margin-left: 0px;
                    }

            &:nth-child(1) {
                            margin-left: 0;
                        }
            &:nth-child(2){
                display: none;            
            }


            a {
                        height: 22px;
                        padding-right: 7px;
                        padding-left: 7px;
                        -webkit-transition: color 350ms ease;
                        transition: color 350ms ease;
                        color: $color-white;
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 300;
                        letter-spacing: 0px;
                        text-decoration: none;
                        display: inline-block;                                               
                    }
                    .active {
                        background-color: $color-cyan-light;
                        color: $color-fgreen;
                        font-size: 14px;
                        font-weight: 400;
                    }
        }
        .active{
            a{
            background-color:$color-cyan; 
            }
        }

}
.mobile-menu {
    display: none;
    @media only screen and (max-width: 990px) {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 999;
        display: block;
        //overflow: scroll;
        width: 100vw;
        height: 100vh;
        background-color: rgba(44, 44, 44, .45);
        transform: translateX(100%) translateY(0px) translateZ(0px);
        transition: transform 400ms;
        transform-style: preserve-3d;
        transition-delay: 0.2s;
        &__inner {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            overflow: auto;
            width: 65%;
            height: 100%;
            float: right;
            background-color: $dark-gray;
            transform-style: preserve-3d;
            display: block;
            transform: translateX(100%) translateY(0px) translateZ(0px);
            transition: transform 400ms;
            @media only screen and (max-width: 640px) {
                width: 100%;
            }
            &-header {
                width: 100%;
                height: 80px;
                margin-bottom: 40px;
                padding-left: 28px;
                position: relative;
                z-index: 1;
                display: block;
                padding-top: 20px;
                padding-right: 20px;
                &__left {
                    width: 50%;
                    float: left;
                    padding-top: 10px;
                    @media only screen and (max-width: 640px) {
                        width:60%;
                    }
                }
                &__right {
                    width: 50%;
                    float: left;
                    text-align: right;
                    a {
                        font-family: $icon-font;
                        color: $color-cyan;
                        font-size: 23px;
                        height: 50px;
                        padding-right: 15px;
                        padding-left: 13px;
                        line-height: 50px;
                        font-weight: 300;
                        letter-spacing: 1px;
                        text-decoration: none;
                        float: right;
                    }
                    .mobil-trenner {
                        width: 1px;
                        height: 30px;
                        margin: 9px 18px 9px 9px;
                        float: right;
                        background-color: hsla(0, 0%, 91%, .3);
                         @media only screen and (max-width: 486px) {
                           margin: 9px 0px 9px 0px;
                         }
                    }
                    @media only screen and (max-width: 640px) {
                        width:40%;
                    }
                }
            }
        }
        &__navigation {
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    margin: 0;
                    padding: 0;
                    color: $color-white;
                    font-size: 20px;
                    font-weight: 300;
                    border-bottom: 1px solid hsla(0, 0%, 85%, .3);
                    position: relative;
                    @media only screen and (max-width: 486px) {
                        font-size: 16px;
                    }
                    &:first-child {
                        &:after {
                            content: ' ';
                        }
                    }
                    &:last-child {
                        border-bottom: 0px;
                    }
                    &:after {
                        content: "\76";
                        position: absolute;
                        font-family: $icon-font;
                        right: 5px;
                        text-align: center;
                        font-weight: bold;
                        margin-right: 35px;
                        font-size: 15px;
                        top: 20px;
                        transform-style: preserve-3d;
                        transition: transform 500ms;
                        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                    }
                    a {
                        color: $color-white;
                        display: block;
                        padding: 17px 0px 17px 35px;
                    }
                    ul {
                        background-image: url(../images/pattern.png);
                        background-color: $color-white;
                        background-position: 0px 0px;
                        background-size: 5px;
                        padding-right: 35px;
                        padding-left: 35px;
                        max-height: 0;
                        overflow: hidden;
                        -webkit-transition: max-height 0.5s;
                        -moz-transition: max-height 0.5s;
                        transition: max-height 0.5s;
                        /* Set our transitions up. */
                        li {
                            padding-left: 0;
                            border: 0;
                            &:after {
                                content: ' ';
                            }
                            a {
                                color: $primary-font-color;
                                padding-right: 15px;
                                font-size: 18px;
                                padding-top: 7px;
                                padding-bottom: 7px;
                                padding-left: 15px;
                                font-weight: 300;
                                letter-spacing: 0px;
                                text-decoration: none;
                                cursor: pointer;
                                border-bottom: 1px solid rgba(44, 44, 44, .11);
                            }

                        &:last-child{
                          a{
                            border-bottom: 0;
                          }
                        }
                        }
                    }
                    .opened {
                        max-height: 1000px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
                .title-act{

                    a{
                        color: $color-cyan-light;
                        border-bottom: 0px;
                    }
                }
                .opened-menu {
                    &:after {
                        transform-style: preserve-3d;
                        transition: transform 500ms;
                        transform: rotateX(0deg) rotateY(0deg) rotateZ(-180deg);
                    }
                    background:$color-cyan;
                }
            }
            .language {
                li {
                    background-color: $dark-background;
                    &:after {
                        content: ' ';
                    }
                    a {
                        font-size: 16px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                    &:last-child {
                        border-bottom: 1px solid hsla(0, 0%, 85%, .3);
                    }
                }
            }
        }
    }
}
.slide-in {
    transform: translateX(0%) translateY(0px) translateZ(0px);
    transition: transform 400ms;
    transform-style: preserve-3d;
    .mobile-menu__inner {
        transform: translateX(0%) translateY(0px) translateZ(0px);
        transition: transform 400ms;
        transform-style: preserve-3d;
        transition-delay: 0.2s;
    }
}

.product-finder{
	position: absolute;
    left: 50%;
    bottom: 0px;
    z-index: 1;
    display: block;
    padding: 10px 20px 20px;
    float: left;
    background-color: $color-cyan;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 200ms cubic-bezier(.645, .045, .355, 1);
    transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 200ms cubic-bezier(.645, .045, .355, 1);
    font-family: Roboto, sans-serif;
    color: $color-white;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    
    &__runter{
    	    position: relative;
		    margin-left: 15px;
		    font-family: Icomoon, sans-serif;
		    color: $color-white;
		    font-size: 22px;
		    font-weight: 800;
            top:5px;
            -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 200ms cubic-bezier(.645, .045, .355, 1);
    transition: background-color 250ms cubic-bezier(.645, .045, .355, 1), color 200ms cubic-bezier(.645, .045, .355, 1);
    }
    &:hover{
        color:$color-medium-black;
        .product-finder__runter{
         color:$color-medium-black;
        }
        a{
            color:$color-medium-black;
        }
    }

}
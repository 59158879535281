.logo{
	visibility: collapse;
	margin-bottom: 39px;
	transform-style: preserve-3d;
    transition: transform 500ms;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    height: 55px;
    transition: height 450ms;
    margin-left: -15px;
    margin-right: -15px;
		@media only screen and (max-width : 1024px){
			margin-left: 0px;
		}
     @media only screen and (max-width : 990px){
        height: auto;
        margin-bottom: 0;
     }
    img{
    max-width: 90%;
    vertical-align: middle;
    display: inline-block;
    width: 180px;
    visibility: visible;
    @media only screen and (max-width : 476px){
    max-width: 140px;margin-top: 15px;
    }
    }
}

.distributor-link{
    position: relative;
    left: 50%;
    bottom: 0px;
    z-index: 2;
    display: block;
    padding: 0 12px 0 15px;
    float: left;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    color: $color-cyan-light;
    font-size: 22px;
    line-height: 25px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    .weiter{
        margin-left: 10px;
        font-family: $icon-font;
        font-size: 13px;
        font-weight: 800;
    }

        &:hover{
            a{
            color:$color-medium-black;
            }
            span{
             color:$color-medium-black;
            }
        }

    @media only screen and (max-width: 960px) {
        line-height: 25px;
        padding-bottom:50px;
        float: none;
        }
        @media only screen and (max-width: 726px) {
            padding-bottom:0px;
        }

}

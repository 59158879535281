.contact-rightpanel {
    font-family: $primary-font-family;
    display: block;
    height: 100%;
    margin-left: 53px;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    transform: translateX(364px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1);
    opacity: 0;
     
    &__open{
        opacity: 1;
        z-index: 10;        
        .adress-panel {
        background-color: $color-white;
        background-image: url("../images/pattern.png");
        background-position: 0 0;
        background-size: 5px auto;
        border-color: $border-grey;
        border-style: solid none;
        border-width: 1px;
        float: right;
        height: 211px;
        position: absolute;
        right: 0;
        top: 0;
        width: 332px;
        z-index: 1;
        overflow: hidden;
    }
    }   
}
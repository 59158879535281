.search-container{
	position: static;
	width: 100%;
	background-color: $color-medium-black;
	font-weight: 300;
	display: block;
    height: 0px;
    transition: height 450ms;
    overflow: hidden;

	input{
		height: 80px;
		padding-left: 0px;
		border: 1px none transparent;
		background-color: transparent;
		font-size: 30px;
		line-height: 60px;
		font-weight: 300;
		color: $color-white;
		width: 100%;
		font-family: $primary-font-family ;
		padding-left: 0px;
		padding-top: 10px;
		padding-bottom: 10px;
		outline: none;
		outline-style: none;
		transition: opacity 200ms;
    	opacity: 0;
    	margin-left: -15px;
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $color-cyan;
		font-family: $primary-font-family;
		font-weight: 300;
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: $color-cyan;
		font-family: $primary-font-family;
		font-weight: 300;
		opacity: 1;
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: $color-cyan;
		font-family: $primary-font-family;
		font-weight: 300;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: $color-cyan;
		font-family: $primary-font-family;
		font-weight: 300;
	}
	&__close{
		position: relative;
		width: 80px;
		height: 80px;
		float: right;
		font-family: Icomoon, sans-serif;
		color: $color-cyan;
		opacity: 1 ;
		font-size: 30px;
		line-height: 80px;
		text-align: right;
		text-decoration: none;
		transition: opacity 200ms;
		right: -20px;
    	opacity: 0;
		&:hover{
			color: $color-cyan ;
		}
	}
	.opacity-addon{
	transition: opacity 350ms ease-in-out; opacity: 1;
}

/*hack for the mobile device 320*/
.col-1{
    @media only screen and (max-width: 320px) {
        position: absolute;
        right: 0;
    }
}

}
.open-search{
	display: block;
    height: 80px;
    transition: height 450ms;
}

.footer-cat{
    &__head{
    color: $color-cyan;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
    text-transform: uppercase;
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            border-bottom: 1px solid rgba(0, 186, 197, 0.2);
            cursor: pointer;
            padding-bottom: 13px;
            padding-top: 10px;
            transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
            width: 100%;
            color: $color-cyan;
            float: left;
            font-size: 14px;
            font-weight: 300;
            text-decoration: none;
            a{
                color: $color-cyan;
                &:hover{
                  text-decoration: underline;
                }
            }
            &:last-child{
                border-bottom: 0px;
            }
        }
    }
    p{
        color: $color-white;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        margin-bottom: 20px;

    }

    .second-column-footer{
        li:first-child{
          max-width:200px;
        }
    }

}

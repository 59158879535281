.submenu-teaser-image-headtext{
	    float: left;
	    background: red;
	    img{
	    	width: 404px;
	        height: 268px;
	        max-width: 100%;
	    }
	    &__title{
		    z-index: 0;
		    display: block;
		    margin-left: 15px;
		    padding: 13px;
		    float: left;
		    background-color: #2c2c2c;
		    font-family: Roboto, sans-serif;
		    color: #fff;
		    font-size: 0.7vw;
		    line-height: 0px;
		    font-weight: 400;
		    letter-spacing: 0.5px;
		    text-transform: none;
		    position: absolute;
		    bottom: 56px;
	    }
	    &__subtitle{
			position: absolute;
		    left: 0px;
		    bottom: 0px;
		    z-index: 1;
		    margin-bottom: 86px;
		    margin-left: 20px;
		    padding: 7px 13px;
		    float: left;
		    clear: left;
		    background-color: #fff;
		    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, .23);
		    font-family: Roboto, sans-serif;
		    color: #00bac5;
		    font-size: 1vw;
		    font-weight: 300;
		    letter-spacing: 1px;
		    text-decoration: none;
		    text-transform: uppercase;
	    }

}
@keyframes circle_highlight {
    0% {
        transition: transform 600ms;
        transform: scaleX(0.81) scaleY(0.81);
    }/*
    50% {
        transition: transform 600ms;
        transform: scaleX(1) scaleY(1);
    }*/
    50% {
        transition: transform 600ms;
        transform: scaleX(1) scaleY(1);
    }

    100% {
        transition: transform 600ms;
        transform: scaleX(.81) scaleY(0.81);
    }
}

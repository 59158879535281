.meta-cal-wrapper{
		padding: 15px 10px 10px 40px;
    text-align: left;
	h1{
		color: #1DBAC4;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    margin: 0;
	}
	h2{
		margin-top: 20px;
		margin-bottom: 7px;
		color: #2C2C2C;
		font-size: 14px;
		line-height: 20px;
		font-weight: 300;
	}
}

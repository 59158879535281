.teaser-text{
	padding-bottom: 40px;
	padding-right: 20px;
    padding-left: 20px;
    border: 1px solid $border-color-light-gray;
    height: 100%;
    p{
    	padding-top: 95px;
    	margin-right: 30px;
    	margin-left: 30px;
    	font-weight: 300;
    	font-size: 16px;
	    line-height: 25px;
			@media only screen and (max-width: 1024px) {
				 padding-top: 110px;
			}
			@media only screen and (max-width: 768px) {
				padding-top: 20px;
			}
      @media only screen and (max-width: 470px) {
            margin-right: 0px;
            margin-left: 0px;
         }
    }
    @media only screen and (max-width: 768px) {
        margin-bottom:60px;
        height: auto;
    }

		.module-link__btn{
			@media only screen and (max-width: 768px) {
				position: relative;
			}
		}
}

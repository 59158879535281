.scroll-wrapper {
    background-color: #fff;
    float: left;
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 395px;
    padding-top: 160px;  
    @media only screen and (max-width: 990px) {
        margin-bottom: 0px;
        padding-top: 130px;
    }

    @media only screen and (max-width: 960px) {
        margin-bottom: 0px;
        padding-top: 130px;
    }
    @media only screen and (max-width: 476px) {
        margin-bottom: 0px;
    }
}

.main-navigation {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -35px;
    height: 50px;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 10px;
    padding: 0;
    background-color: $color-medium-black;
    &__sticky {}
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            position: static;
            margin-right: 5%;
            float: left;
            -webkit-transition: color 250ms cubic-bezier(.645, .045, .355, 1);
            transition: color 250ms cubic-bezier(.645, .045, .355, 1);
            font-family: Roboto, sans-serif;
            color: $color-white;
            font-size: 17px;
            line-height: 50px;
            font-weight: 400;
            letter-spacing: 0.5px;
            text-decoration: none;
            padding-right: 20px;
            padding-left: 20px;
            @media only screen and (max-width: 1360px) {
                margin-right: 2%;
            }
            @media only screen and (max-width: 1200px) {
                margin-right: 0%;
            }
            &:first-child {
                padding-left: 20px;
            }
            a {
                color: $color-white;
                display: block;
                &:hover {
                    //cursor: text;
                    color: $color-cyan;
                }
            }
        }
         .active{
            position: relative;
            &:after{
                content:' ';
                position: absolute;
                width: 100%;
                height: 4px;
                background: #00bac5;
                bottom:0px;
                left: 0;
            }
            a{
              color: #00bac5;
            }
            }
    }
    &__burger-menu {
        position: relative;
        height: 50px;
        padding-right: 15px;
        float: right;
        -webkit-transition: color 250ms cubic-bezier(.645, .045, .355, 1);
        transition: color 250ms cubic-bezier(.645, .045, .355, 1);
        font-family: Icomoon, sans-serif;
        color: $color-white;
        font-size: 24px;
        line-height: 50px;
        font-weight: 300;
        letter-spacing: 1px;
        &:hover {
            color: $color-cyan;
        }
    }
    @media only screen and (max-width: 990px) {
        display: none;
    }
}

.swiper-wrap {
    padding-left: 0;
    padding-right: 0;
    margin-top: -55px;
    z-index: -1;
    @media only screen and (max-width: 990px) {
        //margin-top: 75px;
    }
}
.swiper-container {
    width: 100%;
    margin: 10px 0 0 0;
    .swiper-slide {
        height: 87vh;
        background-position: 50% 100%!important;
        background-size: cover!important;
        background-repeat: no-repeat!important;
        border-bottom: 10px solid $color-cyan;
        @media only screen and (max-width: 990px) {
            background-position: 50% 50%!important;
            height: 89.5vh;
        }
        @media only screen and (max-width: 476px) {
            background-position: 38% 50%!important;
            height: 86vh;
        }
        .headline {
            background: red;
            position: absolute;
            bottom: 0;
        }
        .sub-headline {
            background: red;
            position: absolute;
            bottom: 0;
        }
        .slider-bodyText {
            background: red;
            position: absolute;
            bottom: 0;
        }
    }
}
.footer-bg-container {
    background-image: linear-gradient(180deg, rgba(44, 44, 44, 0.52), rgba(44, 44, 44, 0.52)),
    url("../images/dark-pattern.png");
    background-position: 0 0px, 0 0;
    background-size: auto auto, 5px auto;
    height: 280px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
     @media only screen and (max-width: 470px) {
        height: 310px;
    }
    @media only screen and (max-width: 320px) {
        height: 345px;
    }
    a{
        color: $color-cyan;
    }
    ul{
        list-style: none;
        padding:0;
        margin:0;
        padding-top: 60px;
        li{
            color: $color-white;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            padding-bottom: 15px;
            &:first-child{
                    border-bottom: 1px solid $border-grey-light;
                    padding-bottom: 15px;
                    margin-bottom: 15px;
            }
            a{
                color: $color-cyan;
                cursor: pointer;
                font-size: 14px;
                font-weight: 300;
                margin-top: 10px;
                text-decoration: none;
                transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                position: relative;
                &:hover{
                  text-decoration: underline;
                }
            }
        }
    }
    .internal{
        background-color:$color-cyan;
        color:$color-white;
        cursor: pointer;
        display: block;
        font-family: $primary-font-family;
        font-size: 16px;
        font-weight: 400;
        left: 50%;
        letter-spacing: 0.5px;
        line-height: 22px;
        margin-left: auto;
        margin-right: auto;
        padding: 8px 12px 8px 15px;
        position: absolute;
        text-decoration: none;
        text-transform: uppercase;
        transform: translate(-50%, 0px);
        transition: background-color 250ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, color 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        z-index: 2;
        span{
                margin-left: 10px;
                font-family: $icon-font;
                font-size: 13px;
                font-weight: 800;
        }
        &:hover{
            color: $color-medium-black;
        }
    }
    .pdf-class{
        padding-left: 25px;
        &:before{
        width: 25px;
        height: 25px;
        padding: 5px;
        content: 'P';
        font-family: $icon-font;
        position: absolute;
        left: 0;
        top: -6px;
    }
    i{
        display: none;
    }
    }
}

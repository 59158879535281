.table-grid {
    width: 100%;
    tr {
        th {
            padding-right: 0px;
            padding-left: 20px;
            border-bottom: 4px solid $color-medium-black;
            cursor: pointer;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: right;
            &:first-child {
                padding-left: 20px;
                text-align: left;
            }
            &:last-child {
                padding-right: 20px;
            }
        }
        td {
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $primary-font-color;
            text-align: right;
            p {
                margin-bottom: 0;
            }
            &:first-child {
                padding-left: 20px;
                text-align: left;
            }
            &:last-child {
                padding-right: 20px;
            }
        }
        &:nth-child(even) {
            td {
                background: $module-light-border;
            }
        }
        &:hover {
            background: rgba(0, 186, 197, .15);
        }
    }
}

.meta-navigation{
	min-width: 25%;
    padding-right: 0px;
    float: right;
    transform-style: preserve-3d; transition: transform 500ms; transform: translateX(0px) translateY(0px) translateZ(0px);
    margin-right: -15px;
    &--list{
    list-style: none;
    float: left;
    li{
    height: 22px;
    margin-left: 20px;
    padding-right: 7px;
    padding-left: 7px ;
    float: left;
    -webkit-transition: color 350ms ease;
    transition: color 350ms ease;
   	font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0px;
    text-decoration: none;
    a{
    	color: $dark-gray;
    	font-size: 14px;
		font-weight: 400;
		text-decoration: none;
    }
    &:hover{


		    a{
		    	color: $color-cyan;
		    }
    	}
    }
    .active{
    		background-color: $color-cyan;
		    color: $color-white;
		    a{
		    	color: $color-white;
                &:hover{
                    color: $color-white;
                }
		    }
    	}
    &__language{
    	float: right;
    }
    }
    &--search{
    	float: right;
    	height: 22px;
	    padding-left: 7px;
	    float: right;
	    -webkit-transition: color 350ms ease;
	    transition: color 350ms ease;
	    font-family: Icomoon, sans-serif;
	    color: $dark-gray;
	    font-size: 23px;
	    line-height: 22px;
	    font-weight: 400;
	    letter-spacing: 0px;
	    text-decoration: none ;
        padding-top: 1px;
    }
      @media only screen and (max-width : 990px){
        display:none;
    }
}

.home-publications {
    height: 100%;
    //padding-right: 30px;
    //padding-left: 30px;
    border: 1px solid $module-light-border;
    background-color: $color-white;
    box-shadow: none;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    @media only screen and (max-width: 990px) {
        background-size: 50% auto;
        height: 23em;
        margin-top: 40px;
    }
      @media only screen and (max-width: 768px) {
        height: auto;
        margin-top: 0px;
      }

    &__text {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin-bottom: 10px;
        padding-right: 40px;
        padding-left: 40px;
        font-size: 14px;
        line-height: 19px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 0px;
        color: $primary-font-color;
    }
    img{
        margin-top: 40px;
    }
}

.footer-cat__list-icon{
    font-family: Icomoon,sans-serif;
    font-size: 17px;
    margin-right: 5px;
    position: relative;
    top: 1px;
    font-style: normal;
    left: 0;
     &:before{
        width:25px;
        height:25px;
        position: absolute;
        left: 0px;
        top: 0;
        content: ' ';
    }
}
.footer2-link-icon-1{
    padding-left: 25px;
    &:before{
        content: '\32';
    }

}
.footer2-link-icon-2{
    padding-left: 25px;
    &:before{
        content: '\31';
    }

}
.footer2-link-icon-3{
    padding-left: 25px;
    &:before{
        content: '\37';
    }

}
.footer2-link-icon-4{
    padding-left: 25px;
    &:before{
        content: '\34';
    }

}
.footer2-link-icon-5{
    padding-left: 25px;
    &:before{
        content: '\33';
    }

}

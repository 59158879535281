body {
    margin: 0;
    color: $primary-font-color;
    font-family: $primary-font-family;
    overflow-x: hidden;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
}
.row{
  clear: both;
}

select::-ms-expand {	display: none; }

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
    >.col, >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
    .container {
        padding-right: 0px;
        padding-left: 0px;
    }
}
ul, ol{
    margin:0;
    padding:0;
    li{
    margin:0;
    padding:0;
    }
}
.container {
    width: 100%;
    max-width: 1400px;
}
img {
    vertical-align: middle;
    max-width: 100%;
}

.text-justify {
    text-align: justify !important;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 25px;
}

.separation-full-width {
    padding-top: 100px;
    padding-bottom: 100px;
    @media only screen and (max-width: 768px) {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 600px) {
        padding-right: 0;
        padding-left: 0;
        padding-top: 20px;
        padding-bottom: 10px;
    }
}
a {
    color: $color-cyan;
    text-decoration: none;
}
p {
    font-weight: 300;
    a {
        color: $color-cyan;
        text-decoration: none;
        &:hover {
            color: $color-cyan;
            text-decoration: underline;
        }
    }
}

.fancybox-button--play{
  display: none!important;
}

iframe {
        width: 100%;
}
.content-min-wrapper{
  //min-height: 750px;
}
.noscrolling{
  overflow: hidden;
  position: fixed;
}
.noscrolling-news{
    overflow: hidden;
}

.fancybox-is-open{
    .fancybox-stage{
        display: block !important;
    }
}
.news-story-print{
    margin-bottom: 1rem;
}
.breadcrumb {
    background: none;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 40px;
    margin-top: 20px;
    font-size: 14px;
    padding: 0rem 0rem;
    .trenner {
        display: inline-block;
        font-family: $icon-font;
        padding: 0 10px;
    }
    a {
        color: $color-cyan-light;
        font-weight: 400;
        cursor: pointer;
    }
}
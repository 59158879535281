.btn-wrapper{
	position: relative;
	min-height: 40px;
	@media only screen and (max-width: 768px ){
		min-height: 100px;
	}

	@media only screen and (max-width: 468px ){
		min-height: 40px;
	}
}

.module-wrapper{
	.btn-wrapper{
		text-align: center;
	}
}

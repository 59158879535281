.module-subheading{
	display: block;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    float: none;
    text-align: center;
    font-weight: 300;
    @media only screen and (max-width: 476px) {
        width: 100%;
    }
    h2{
    	width: 100%;
	    margin-top: 0px;
	    margin-bottom: 20px;
	    float: left;
	    clear: left;
	    font-family: $primary-font-family;
	    color: $color-cyan-light;
	    font-size: 28px;
	    line-height: 34px;
	    font-weight: 300;
        @media only screen and (max-width: 476px) {
            text-align: left;
						padding: 0 10px;
         }
				 @media only screen and (max-width: 360px) {
             text-align: center; 						
          }
    }
    .text-center{
    	 margin-bottom: 40px;
        @media only screen and (max-width: 476px) {
            text-align: left!important;
         }
    }
    .btn-wrapper{
        @media only screen and (max-width: 476px) {
        margin-bottom: 50px;
        }
    }
    .module-link__btn{
    	text-transform: none;
    	font-size: 24px;
    	font-weight: 300;
        @media only screen and (max-width: 476px) {
            position: relative;
            font-size: 18px;
            line-height: 43px;
            padding-top: 0px;
            padding-bottom: 0px;
         }
    }
}

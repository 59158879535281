.home-seperation-container{
    background-attachment: scroll, fixed;
    background-color: transparent;
    background-image: url("../images/pattern-v02.png"),
    url("../images/hr-background.jpg");
    background-position: 0 0px, 0 0;
    background-repeat: repeat, no-repeat;
    background-size: 5px auto, cover;
    padding-bottom: 100px;
    position: relative;
    width: 100vw;
    z-index: 1;
    padding-top: 0px;
    text-align: center;
    .home-section-headline {
        margin-top: 40px;
        @media only screen and (max-width: 768px) {
        margin-top: 0px;
         }
    }

     @media only screen and (max-width: 476px) {
        padding-bottom: 25px;
     }
}

.parralax-model-image{
    display: none;
}

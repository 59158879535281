.stripe-container{
		border-top: 1px solid $module-light-border;
		border-bottom: 1px solid $module-light-border;
    background-color: $color-white;
    background-image: url(../images/pattern.png);
    background-size: 5px;
		.distributor-link{
			float: none;
		}
}

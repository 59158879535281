.latest-news {
    &__link {
        color: $color-cyan-light;
        &:hover {
            color: $color-cyan-light;
            text-decoration: underline;
        }
    }
}
.newsloadmore {
    position: relative;
    left: 50%;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 12px;
    float: left;
    background-color: $color-cyan-light;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    color: $color-white;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 400;
    &:hover {
        color: $color-black;
    }
}

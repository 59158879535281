.accordion {
     margin-top: 40px;
     margin-bottom: 40px;
    .accordion-panel {
        .panel {
            .panel-heading {
                width: 100%;
                background-color: $color-white;
                position: relative;
                clear: both;
                .numbering-icon {
                    margin-right: 20px;
                    margin-left: 20px;
                    margin-top: 7px;
                    padding: 5px 0;
                    border-radius: 100%;
                    background-color: $color-cyan-light;
                    color: $color-white;
                    font-weight: 500;
                    position: relative;
                    box-sizing: border-box;
                    width: 33px;
                    height: 33px;
                    text-align: center;
                    float: left;
                }
                h4{
                    margin:0;
                }
                a{
                    display: block;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    padding-left: 20px;
                    padding-right: 20px;
                    background: $module-light-border;
                    color: $color-cyan-light;
                    padding-left: 20px;
                    padding-right: 20px;
                    font-weight: 300;
                    font-size: 16px;
                    font-family: $primary-font-family;
                    padding-right: 50px;
                    @media only screen and (max-width: 360px) {
                      padding-left: 70px;
                    }
                    .header-content-field{
                        //padding-left: 50px;
                    }
                    &:after{
                            content: "\76";
                            font-family: 'icomoon';
                            position: absolute;
                            top: -6px;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            margin-right: 20px;
                            width: 1em;
                            height: 1em;
                            color: $color-cyan-light;
                            font-size: 15px;
                            transform-style: preserve-3d;
                            transition: transform 500ms;
                            transform: rotateX(0deg) rotateY(0deg) rotateZ(-180deg);
                    }
                }
                .collapsed {
                    display: block;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    background: $color-white;
                    border-bottom: 1px solid $border-grey;
                    color: $color-cyan-light;
                    &:hover{
                        background-color:$very-light-cyan;
                    }

                    &:after{
                           transition: transform 500ms;
                            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);

                    }
                    @media only screen and (max-width: 768px) {
                    padding-right:40px;
                }

                }
            }
            .panel-body {
                    padding: 40px 60px 40px 80px;
                    text-align: justify;
                    font-weight: 300;
                    position: relative;
                    overflow: hidden;
                    background-color: #fff;
                    background-image: url(../images/pattern.png);
                    background-position: 0px 0px;
                    background-size: 5px;
                    border-bottom: 1px solid $border-grey;

                    @media only screen and (max-width: 476px) {
                    padding: 40px 20px 40px 20px;
                }
            }
    .image-holder{
      img{
          @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
      }
      }
      }
      }
    }

 @media only screen and (max-width: 768px) {
    margin-bottom: 60px;
}
@media only screen and (max-width: 420px) {
    margin-top:0;
}
}

.landing-page-slider {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    @media only screen and (max-width: 476px) {
    display: none;
    }

    .swiper-slide {
        position: relative;
        background-position: center;
        background-size: cover;
        -webkit-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
        opacity: 0 !important;
        &-active {
            opacity: 1 !important;
        }
    }
    h1 {
        width: 100%;
        color: $color-cyan-light;
        font-size: 2.7em;
        line-height: 50px;
        font-weight: 300;
        text-align: left;
        letter-spacing: 0px;
        text-transform: uppercase;
        padding: 0;
        margin-top:0px;
        margin-bottom: 40px;
    }
    .body-text-box {
        position: absolute;
        left: 50%;
        bottom: 0px;
        margin-bottom: 66px;
        padding: 17px 23px;
        background-color: $color-cyan-light;
        -webkit-transform: translate(-50%, 0px);
        -ms-transform: translate(-50%, 0px);
        transform: translate(-50%, 0px);
        -webkit-transition: color 400ms cubic-bezier(.645, .045, .355, 1);
        transition: color 400ms cubic-bezier(.645, .045, .355, 1);
        color: $color-white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
                bottom: 100px;
           }
        p {
            margin-bottom: 0px;
        }
        .banner-text {
            font-size: 16px;
            line-height: 28px;
            font-weight: 500;
        }
        &:hover{
            color:$color-medium-black;
        }
    }
    .plain-text-box {
        margin-top: 200px;
        text-align: center;
        color:$color-medium-black;
        font-weight: 700;
        width:50%;
        text-align: center;
    }
}
.slider-item.module-wrapper {
    position: absolute;
    @media only screen and (max-width: 768px) {
       top:0px;
    }

    @media only screen and (max-width: 576px) {
       top:0px;
    }

}

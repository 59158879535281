.home-product-container {
    border-bottom: 1px solid $border-color-light-gray;
    background-color: $color-white;
    background-image: url(../images/pattern.png);
    background-size: 5px;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 40px;
    margin-left: -15px;
    margin-right: -15px;
 @media only screen and (max-width: 476px) {
        padding: 40px 30px;
    }
    .module-link__btn{
        @media only screen and (max-width: 476px) {
        display: block;
        margin-top: 50px;
        margin-right: 20px;
        margin-left: 20px; 
    }
    }
 }
    .home-section-headline {
        color: $color-cyan-light;
        font-size: 40px;
        font-weight: 300;
        line-height: 38px;
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
    @media only screen and (max-width: 476px) {
        font-size: 30px;
        line-height: 30px;
        padding-right: 0px;
        padding-left: 0px;
    }
    }
    .home-section-sub-headline {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 40px;
        text-align: center;
    @media only screen and (max-width: 476px) {
        font-size: 16px;
        padding-right: 50px;
        padding-left: 50px;
    }
    }
    .product-column {
        float: left;
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        width: 20%;
        background-image: linear-gradient(180deg, $color-white, $color-white);
        border-left: 1px solid rgba(44, 44, 44, 0.07);
        border-top: 1px solid rgba(44, 44, 44, 0.07);
        box-shadow: 0 4px 7px -2px rgba(44, 44, 44, 0.25);
        padding-bottom: 90%;
        position: relative;
        width: 100%;
    }
    .internal-link__btn {
        text-transform: uppercase;
}

.contact-sliding-wrap{
	position: relative;
	width: 100%;
	display: block;
    height: 0px;
    transition: height 450ms;
    overflow: hidden;
    .container-fluid{
    	padding-left: 0;
    	padding-right: 0;
    }
	&__open{
	height: 200px;
	display: block;
	transition: height 450ms ease-in-out;
	@media only screen and (max-width: 768px) {
		display: none;
	}
	}
	&__close{
		position: absolute;
		right: 17px;
		top: 0;
		font-family: Icomoon, sans-serif;
	    color: $color-white;
	    font-size: 30px;
	    text-decoration: none;
	    cursor: pointer;
	    padding: 0px 9px;
    	background-color: $color-cyan;
	}
	&__contact-wrapper{
		display: block;
	    height: 200px;
	    border-right: 1px solid $border-grey;
	    border-bottom: 1px solid $border-grey;
	    background: url(../images/pattern.png);
	    background-position: 0px 0px;
	    background-size: 5px;
	    position: relative;
    	padding: 30px 30px 20px;
    	text-align: center;
    	background-color:$color-white;
	  .external-link{
	    	display: block;
		    margin-bottom: 14px;
		    -webkit-transition: color 350ms cubic-bezier(.645, .045, .355, 1);
		    transition: color 350ms cubic-bezier(.645, .045, .355, 1);
		    color: $color-cyan;
		    font-size: 18px;
		    font-weight: 300;
		    text-align: center;
		    cursor: pointer;
		    &:hover{
		    	color: $color-cyan-over;
		    }
	    }
	    .mail{
	    	display: block;
		    margin-bottom: 14px;
		    -webkit-transition: color 350ms cubic-bezier(.645, .045, .355, 1);
		    transition: color 350ms cubic-bezier(.645, .045, .355, 1);
		    color: $color-cyan;
		    font-size: 18px;
		    font-weight: 300;
		    text-align: center;
		    cursor: pointer;
		    &:hover{
		    	color: $color-cyan-over;
		    }
	    }
	    .text-center{
	    	display: block;
    		font-size: 14px;
    		line-height: 19px;
    		font-weight: 400;
    		text-align: center;
				@media only screen and (max-width: 1024px) {
					margin-bottom: 10px;
					line-height: 16px;
				}
	    }
	    .contact-button{
	    	position: relative;
		    margin-top: 2px;
		    padding: 12px 17px;
		    background-color: $color-cyan;
		    -webkit-transition: color 300ms cubic-bezier(.645, .045, .355, 1);
		    transition: color 300ms cubic-bezier(.645, .045, .355, 1);
		    font-family: Roboto, sans-serif;
		    color: $color-white;
		    font-weight: 400;
		    cursor: pointer;
		    bottom: 0px;
		    display: inline-block;
		    text-decoration: none;
    		text-transform: uppercase;
    		font-size: 14px;
    		line-height: 18px;
    		margin: 0 auto;
    		&:hover{
				color:$dark-gray;
    		}
				@media only screen and (max-width: 1280px) {
					position: absolute;
					bottom: 8px;
					left: 0;
					right: 0;
					width: 70%;
				}

	    }
	    .icon-phone {
	    position: relative;
	    top: 1px;
	    margin-right: 10px;
	    font-family: Icomoon, sans-serif;
	    font-size: 17px;

		}
	}
}

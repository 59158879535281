.meta-socialmedia-wrapper{
  padding:15px 10px 10px 40px;
  text-align: left;
  h2{
  color: $color-cyan;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  margin: 0;
  }
  p{
    a {
        color: $color-cyan;
        cursor: pointer;
        margin-top: 13px;
        font-family: Icomoon, sans-serif;
        font-size: 26px;
        line-height: 31px;
        padding-left: 12px;
        padding-right: 12px;
        text-decoration: none;
        transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        display: inline-block;
        &:first-child{
          padding-left: 0;
        }
        &:hover{
          color: $color-black;
        }
    }
  }
}

$primary-font-color: #2C2C2C;
$primary-font-family: 'Roboto', sans-serif ;
$icon-font: 'icomoon', sans-serif ;
$dark-gray: #2C2C2C;
$color-cyan: #1DBAC4;
$color-cyan-light: #00bac5;
$color-cyan-very-light: #01cad6;
$color-cyan-over: #00e4f1;
$color-white: #FFFFFF;
$color-green: #D7F0B9;
$color-red: #CD3120;
$color-black: #000;
$color-medium-black: #2C2C2C;
$color-very-darkgrey: #999999;
$border-grey: #d8d8d8;
$border-grey-light: #585858;
$very-dark-grey: #1d1d1d;
$cyan-hover:#00f1ff;
$dark-background:#424242;
$color-fgreen:#d7f0b7;
$dark-grey-bg:#464646;
$border-color-light-gray:#e7e7e7;
$module-light-background:#f6f6f6;
$module-light-border:#f3f3f3;
$bullet-color:#222;
$very-light-cyan:#d0f4f6;
$orange-text:#cf3415;

//Columns for management
$three-colmn-width:31.33333333%;

.download{
    &-module{
    position: relative;
    width: 100%;
    min-height: 283px;
    float: right;
    clear: none;
    border: 1px solid $border-color-light-gray;
    background-color: $color-white;
    @media only screen and (max-width: 990px) {
        margin-bottom: 40px;
    }
    @media only screen and (max-width: 375px) {
        float: none;
        margin:0 auto;
    }

    .icon-factsheet {
        display: block;
        height: 130px;
        margin-top: 61px;
        font-family: Icomoon, sans-serif;
        color: $color-cyan-light;
        font-size: 100px;
        line-height: 130px;
        text-align: center;
        text-decoration: none;
        display: none;
    }

        img{
          width: 100%;
        }
    }
    &__text{
            position: absolute;
            left: 0px;
            bottom: 0px;
            margin-bottom: 0px;
            padding-right: 40px;
            padding-left: 40px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 300;
            text-align: center;
            letter-spacing: 0px;
            color:$primary-font-color;
        }
}

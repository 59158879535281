.meta-navigation-side-contact{
	padding:15px 10px 10px 40px;
	text-align: left;
	opacity: 0;
    h1{
    color: $color-cyan;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    margin: 0;
    }
	    .bodytext{
		   	margin-top: 20px;
		    margin-bottom: 7px;
		    color: $primary-font-color;
		    font-size: 14px;
		    line-height: 20px;
		    font-weight: 300;
	    }
	    ul{
	    	list-style: none;
	    	li{
	    		background-color: transparent;
	    		padding: 0;
				margin: 0;
				height: 32px;
				color: $color-cyan;
	    		font-size: 14px;
	    		padding-left: 40px;
    			line-height: 32px;
    			font-weight: 400;
    			display: block;
				&:before{
					display:none;
				}
				&:after{
					display: none;
				}
				.icon-p{
						&:after{
						position:absolute;
    					top: 1px;
					    margin-right: 5px;
					    font-family: Icomoon, sans-serif;
					    font-size: 17px;
					    content:'\70' ;
					    top:0;
					    display: block;
					    width: 25px;
					    height: 25px;
					    color:$color-cyan;
					    left: 15px;
					    top: 4px;
						}
					}
				.icon-c{
						&:after{
						position:absolute;
    					top: 1px;
					    margin-right: 5px;
					    font-family: Icomoon, sans-serif;
					    font-size: 17px;
					    content:'\63' ;
					    top:0;
					    display: block;
					    width: 25px;
					    height: 25px;
					    color:$color-cyan;
					    left: 15px;
					    top: 6px;
						}
					}
	    		a{
	    			background-color:transparent;
	    			color: $color-cyan;
	    			font-size: 14px;
					cursor: pointer;
					padding:0px;
					&:hover{
		    		color:$cyan-hover;
		    		background: none;
		    		}

	    		}
	    		&:last-child{
	    			&:after{
					    position: absolute;
					    width: 150px;
					    height: 1px;
					    background: $color-cyan;
					    display: block;
					    left: -135px;
					    padding: 0px;
					    top: 0px;
					    transition: none;
				}
				&:hover{
	    		color:$cyan-hover;
	    		background: none;
	    		.icon-c{
	    			&:after{
	    				color:$cyan-hover;
	    			}
	    		}
	    		}

	    		}
	    		&:hover{
	    		color:$cyan-hover;
	    		background: none;
	    		.icon-p{
	    			&:after{
	    				color:$cyan-hover;
	    			}
	    		}
	    	}
				&:first-child{
					    border-bottom: 1px solid rgba(0, 186, 197, 0.2);
				}
	    	}

	    }
}


.contact-rightpanel__open{
	.meta-navigation-side-contact{
            opacity: 1;
            transition: opacity 0.1s 0.5s ease;
        }
}

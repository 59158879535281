.thema-title-top {
    text-decoration: none;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 10px 50px 10px 15px;
    background-color: $color-cyan;
    -webkit-transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    transition: background-color 250ms cubic-bezier(.645, .045, .355, 1);
    color: $color-white;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    &:before {
        margin-left: 10px;
        font-family: Icomoon, sans-serif;
        font-size: 13px;
        font-weight: 800;
        position: absolute;
        content: '-';
        width: 25px;
        height: 25px;
        right: 10px;
    }
    a{
        color: $color-white;
    }
    &:hover{
            color:$color-medium-black;
        a{
            color:$color-medium-black;
        }
    }
    
}
.button-no-background {
    float: left;
    .module-link__btn{
        background-color:#fff; 
        color: $color-cyan;
        &:hover{
            color:$color-medium-black;
        }
    }    
}
.button-background {
    float: left;
}
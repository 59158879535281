.table-wrapper{
  overflow-x: auto;
  position: relative;
  @media only screen and (max-width : 990px){
  margin-left: -15px;
  margin-right: -15px;
  }
  @media only screen and (max-width: 468px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  .contenttable {
      width: 100%;
        @media only screen and (max-width : 990px){
        width: 990px;
        float: none;
        }
      }
}
.contenttable {
    width: 100%;
    tr{
        th{
            padding-right: 20px;
            padding-left: 20px;
            border-bottom: 4px solid $color-very-darkgrey;
            cursor: pointer;
            font-weight: 300;
        }

        td{
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-very-darkgrey;
            text-align: right;
            font-weight: 300;
            @media only screen and (max-width : 990px){
            padding-right: 10px;
            }
            p{
                margin-bottom: 0;
                @media only screen and (max-width : 990px){
                  word-break: break-all;
                }
            }
            &:first-child{
                padding-left: 20px;
                text-align: left;
            }

            &:last-child{
                padding-right: 20px;
            }

        }
        &:nth-child(even){
            td{
                background: $module-light-border;
            }
        }
         &:hover{
                background: rgba(0, 186, 197, .15);
            }
    }
}

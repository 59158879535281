.module-download-box {
    .download{
        padding: 12px;
        background-color: $color-cyan-light;
        color: $color-white;
        text-decoration: none;
        height: 43px;
        margin-top: 5px;
        padding-top: 0px;
        padding-bottom: 0px;
        transition: color 300ms ease;
        line-height: 45px;
        padding-left: 45px;
        cursor: pointer;
        display: inline-block;
        float: left;
        clear: both;
        &:hover{
                color: $primary-font-color;
        }
        .download-icon-pdf{
            font-family: $icon-font;
            position: absolute;
            margin-right: 15px;
            font-size: 24px;
            left: 12px;
        }

    }
}

.teaser-news-event {
    background-color: $color-cyan-light;
    .calender-wrap {
        display: block;
        .calender-date {
            width: 200px;
            height: 200px;
            margin-top: 60px;
            margin-right: auto;
            margin-left: auto;
            border-radius: 100%;
            background-color: $color-fgreen;
            -webkit-transition: border 300ms ease;
            transition: border 300ms ease;
            cursor: pointer;
            color: $color-medium-black;
            font-size: 86px;
            font-weight: 700;
            line-height: initial;
            padding-top: 45px;
            span {
                font-size: 16px;
                display: block;
                line-height: 15px;
                font-weight: 400;
            }
            &:hover {
                background-color: $color-white;
            }
            @media only screen and (max-width: 375px) {
              margin-top: 30px;
            }
        }
    }
    .calender-text {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin-bottom: 25px;
        padding-right: 40px;
        padding-left: 40px;
        font-size: 14px;
        line-height: 19px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 0px;
        color: $color-medium-black;
    }
    .tx-cal-controller {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        display: table;
        left: 0px;
    }
    .icon-event-calender {
        font-family: $icon-font;
        color: $color-fgreen;
        font-size: 170px;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        @media only screen and (max-width: 480px) {
           font-size: 320px;
        }
    }
    .date-container {
        position: relative;
        display: block;
        height: 100%;
        -webkit-transition: color 300ms ease;
        transition: color 300ms ease;
        color: $color-medium-black;
        line-height: 15px;
        text-align: center;
        span {
            display: block;
            color: $color-medium-black;
            font-size: 86px;
            font-weight: 700;
            padding: 20px 0;
            &:first-child {
                font-size: 16px;
                font-weight: 400;
                transition: color 300ms ease;
                color: $color-medium-black;
                line-height: 15px;
                text-align: center;
            }
        }
        @media only screen and (max-width: 470px) {}
    }
    .summary {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin-bottom: 25px;
        padding-right: 40px;
        padding-left: 40px;
        font-size: 14px;
        line-height: 19px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 0px;
        color: $color-medium-black;
    }
    @media only screen and (max-width: 990px) {
        background-size: 50% auto;
        height: 23em;
    }
}
.finanzmeldungen-news {
    padding-left: 30px;
    padding-right: 30px;
}
.latest-event-calender {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin-bottom: 25px;
    padding-right: 40px;
    padding-left: 40px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0px;
    top: 34%;
}

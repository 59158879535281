.header-sticky-wrapper {
    position: fixed;
    z-index: 20;
    display: block;
    width: 100vw;
    // margin-left: -15px;
    // margin-right: -15px;
    @media only screen and (max-width: 990px) {
    	margin-left: 0px;
    	margin-right: 0px;
    }
}
.header-fade {

}

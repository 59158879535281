.submenu-teaser-career{
	h1{
		padding-top: 22px;
    	padding-left: 20px;
    	color: $color-cyan-light;
    	font-size: 24px;
    	line-height: 22px;
    	font-weight: 300;
    	text-transform: inherit;
    	margin:0;
	}    
	img{
		position: relative;
	    max-width: 94%;
	    margin-top: 34px;
	    margin-left: 20px;
	    float: left;
	    clear: left;
	    box-shadow: 0 7px 5px -7px rgba(0, 0, 0, .24);
	    vertical-align: middle;
    	display: inline-block;
	}
}